import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LANDING } from 'constants/routes';
import { useSocket } from 'utils/hooks/useSocket';
import DashboardWrapper from 'components/layout/DashboardWrapper';
import MobileDesktop from 'components/mobile/MobileDesktop';
import UserState from 'components/dashboard/UserState';
import TradeHistory from 'components/dashboard/TradeHistory';
import GraphBox from 'components/dashboard/GraphBox';
import BuySell from 'components/dashboard/BuySell';
import TournamentRanking from 'components/dashboard/TournamentRanking';
import ModalFull from 'components/ui/ModalFull';
import Footer from 'components/ui/Footer';

import { WalletDataProvider } from 'context/WalletDataContext';
import { TradeUpdateProvider } from 'context/TradeUpdateContext';
import { useTournament } from 'context/TournamentContext';

import JoinEnd from 'components/Join/JoinEnd';
import EndWrap from 'components/layout/EndWrap';
import { BrowserView, MobileView } from 'react-device-detect';

import {
  Article,
  Aside,
  TitleMobile,
  RwdContent,
  ColWrap,
  LinkText,
  LoadFullPage,
  LoadWrap,
} from './styled';

const Game = () => {
  const [setModalOpen] = useState(false);
  const { socket } = useSocket();
  const { joinedStatus, tournamentDetails, tournamentStatus } = useTournament();
  const result = joinedStatus ? "twoBox" : "oneBox";
  let finishTimeModal;


  const [isloadPage, setLoadPage] = useState<Promise<boolean | void> | boolean>(true);
    useEffect(() => {

      const timeout = setTimeout(() => setLoadPage(false), 1000)
      return () => clearTimeout(timeout);

    }, [])
    if (isloadPage) {
        return <LoadFullPage><LoadWrap></LoadWrap></LoadFullPage>;
    };

  return (
    <TradeUpdateProvider>
      <DashboardWrapper>
        {tournamentStatus === 'BEFORE_START' &&
          <Link to={LANDING}>
            <LinkText>BACK</LinkText>
          </Link>
        }

        {socket?.connected && tournamentDetails && (
          <WalletDataProvider tournamentDetails={tournamentDetails}>
            <>

              {joinedStatus && (tournamentStatus === 'IN_PROGRESS') ? (
                <>{finishTimeModal}</>
              ) : null}

              <Article>
                {tournamentStatus === 'IN_PROGRESS' &&
                  <>
                    <RwdContent>
                      <MobileView>
                        <TitleMobile>{tournamentDetails?.data?.attributes?.name}</TitleMobile>
                      </MobileView>
                      <GraphBox tournamentDetails={tournamentDetails} />
                    </RwdContent>
                    <BuySell tournamentDetails={tournamentDetails} />

                    <BrowserView>
                      <div className="hideMobile">
                        <TradeHistory tournamentDetails={tournamentDetails} />
                      </div>
                    </BrowserView>
                  </>
                }
                {tournamentStatus === 'FINISHED' &&
                  <>
                  <ColWrap className={result}>
                    <EndWrap>
                      <JoinEnd tournamentDetails={tournamentDetails} />
                    </EndWrap>
                    <TradeHistory tournamentDetails={tournamentDetails} />
                  </ColWrap>
                  </>
                }
              </Article>

              <BrowserView>
                <Aside className="hideMobile">
                  <TournamentRanking />
                  <UserState tournamentDetails={tournamentDetails} />
                  <Footer />
                </Aside>
              </BrowserView>
              <MobileView>
                <MobileDesktop />
              </MobileView>
            </>
          </WalletDataProvider>
        )}
        {setModalOpen && <ModalFull tournamentDetails={tournamentDetails} />}
      </DashboardWrapper>
    </TradeUpdateProvider>
  );
}
export default Game;
