import React from 'react';

import {
  Box,
  LinkBtn,
} from './styled';


const Footer = () => {
  return (
    <Box>
      <LinkBtn to={'https://tradingbattles.com/terms-and-conditions/'}>Terms and Conditions</LinkBtn>
      <a href="mailto:contact@tradingbattles.com">Contact</a>
    </Box>
  );
};

export default Footer;
