import styled from 'styles/styled';
import { StyledLink } from 'components/ui/StyledLink/StyledLink';

export const Box = styled.div`
  background-color: #15191E;
  border-radius: 12px;
  padding: 15px 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    margin: 0 4px;
    color: #EFEFEF;
  }
`;

export const LinkBtn = styled(StyledLink)`
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  margin: 0 4px;
  color: #EFEFEF;
`;
