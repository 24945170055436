
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ErrorBoundary from 'components/layout/ErrorBoundary';

import ForgotPassword from 'screens/Unauthenticated/ForgotPassword';
import PasswordReset from 'screens/Unauthenticated/PasswordReset';
import ForgotPasswordSendEmail from 'screens/Unauthenticated/ForgotPasswordSendEmail';
import ResetPasswordSuccess from 'screens/Unauthenticated/ResetPasswordSuccess';

import LoginPage from 'screens/Unauthenticated/Login';
import Register from 'screens/Unauthenticated/Register';
import Dashboard from 'screens/Authenticated/Game';
import Results from 'screens/Authenticated/Results';
import Logout from 'screens/Unauthenticated/Logout';
import PageNotFound from 'screens/Unauthenticated/PageNotFound';

import * as routes from 'constants/routes';
import { SocketProvider } from 'utils/hooks/useSocket';
import { UserProvider, useUser } from 'api/userService';
import { GameRouter, TournamentWrapper } from 'context/TournamentWrapper';
import IframeHandler from 'screens/Unauthenticated/IframeHandler';
import TagManager from 'react-gtm-module'
const tagManagerArgs = {
  gtmId: 'G-QPZT0R7PB5'
}
TagManager.initialize(tagManagerArgs)

const App = () => {
  const isIframeMode = window.location.search.includes('iframeMode=true');
  return (
    <Router>
      <UserProvider>
        {isIframeMode ? <IframeHandler /> : <FullApp />}
      </UserProvider>
    </Router>
  );

};

const FullApp = () => {
  return (
    <AppContent />
  );
};
const AppContent = () => {
  const { isLoggedIn } = useUser();

  return (
    <ErrorBoundary>
      <Routes>
        <Route path={routes.HOME} element={isLoggedIn ? <TournamentWrapper><SocketProvider><Dashboard /></SocketProvider></TournamentWrapper> : <TournamentWrapper><LoginPage /></TournamentWrapper>} />
        <Route path={routes.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={routes.FORGOT_PASSWORD_SEND_EMAIL} element={<ForgotPasswordSendEmail />} />
        <Route path={routes.PASSWORD_RESET} element={<PasswordReset />} />
        <Route path={routes.RESET_PASSWORD_SUCCESS} element={<ResetPasswordSuccess />} />
        <Route path={routes.REGISTER} element={<Register />} />
        <Route
          path={routes.JOINTOURNAMENT}
          element={
            <TournamentWrapper>
              <SocketProvider>
                <GameRouter />
              </SocketProvider>
            </TournamentWrapper>
          } />
          <Route
            path={routes.RESULTS}
            element={
              <TournamentWrapper>
                <SocketProvider>
                  <Results />
                </SocketProvider>
              </TournamentWrapper>
            } />
        <Route
          path={routes.GAME}
          element={
            <TournamentWrapper>
              <SocketProvider>
                <GameRouter />
              </SocketProvider>
            </TournamentWrapper>
          }
        />
        <Route path={routes.LOGOUT} element={<Logout />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </ErrorBoundary>
  );
};
export default App;
