import React, { useEffect, useState, useContext } from "react";
import GraphFilter from "components/dashboard/GraphFilter";
import WrapAsset from "components/Assets/WrapAsset";
import SocketContext from 'utils/hooks/useSocket';
import { useSocket } from "utils/hooks/useSocket";
import { formatCurrency, formatCurrencySHIB } from "utils";
import TradingView from "../TradingView";
import { useWalletData } from "context/WalletDataContext";
import moment from "moment";
import { BrowserView, MobileView } from 'react-device-detect';
import { useTournament } from 'context/TournamentContext';

import {
  Box,
  Wrap,
  Amount,
  Currency,
  BeltInformationDesktop,
  BeltInformationMobile,
  InfoBlock,
  Text,
  Title,
  GraphWrapper,
  InfoBox,
  Row,
  TitleSmall,
  Info,
  FlexWrap,
  LoadWrap,
} from "./styled";


const GraphBox = (props: { tournamentDetails: any }) => {
  const { tournamentPairList } = useTournament();
  const { tournamentDetails } = props;
  const [timeLeft, setTimeLeft] = useState("");
  const [activeTime, setActiveTime] = useState("1s");
  const [chartType, setChartType] = useState("Candles");

  const {
    rank
  } = useSocket();

  const { setAllCoins, selectedCoin, selectedCoinValue } = useContext(SocketContext);
  let price = selectedCoinValue.last
  let currency24hData = selectedCoinValue;
  const { totalMoneyString } = useWalletData();
  const tradingPairsList = tournamentPairList;

  const totalUSDT = totalMoneyString;
  const initialMoney = tournamentDetails?.data?.attributes?.amount_start || 1;
  const percentageChange = ((totalUSDT - initialMoney) / initialMoney) * 100;

  const parseTotalMoney = formatCurrency(totalMoneyString);
  const parsePercentageChange = percentageChange.toFixed(2);

  const calculateTimeLeft = () => {
    const now = moment().utc();
    const end = moment.utc(tournamentDetails?.data?.attributes?.end_at);
    const duration = moment.duration(end.diff(now));

    const totalDays = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    setTimeLeft(`${totalDays}d ${hours}h ${minutes}m ${seconds}s`);
  };

  useEffect(()=>{
    setAllCoins(tradingPairsList)
  },[tradingPairsList])


  useEffect(() => {
    calculateTimeLeft();
    const intervalId = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(intervalId);
  }, [tournamentDetails?.data?.attributes?.end_at]);


  return (
    <>
      <Box>
        <Wrap>
          <FlexWrap>

            <BrowserView>
              <BeltInformationDesktop>
                <Currency>{selectedCoin && (selectedCoin?.name || selectedCoin)}</Currency>
                <Amount>
                  {price && !isNaN(price) ? <p>{selectedCoin?.name === 'SHIB/USDT' ? formatCurrencySHIB(price) : formatCurrency(price)}</p> : <LoadWrap/>}
                </Amount>

                <InfoBlock>
                  <Title>24h Change</Title>
                  <Text className={currency24hData.change >= 0 ? "up_state" : "down_state"}>
                    {currency24hData.change && !isNaN(currency24hData.change) ?
                       <p>
                       {selectedCoin?.name === 'SHIB/USDT' ? formatCurrencySHIB(currency24hData.change) : formatCurrency(currency24hData.change)}
                       {currency24hData.percentage >= 0
                         ? ` +${currency24hData?.percentage?.toFixed(2)}%`
                         : ` ${currency24hData?.percentage?.toFixed(2)}%`}
                      </p> : <span>...</span>
                    }
                  </Text>
                </InfoBlock>
                <InfoBlock>
                  <Title>24h High</Title>
                  <Text>
                    {currency24hData.high24h && !isNaN(currency24hData.high24h) ? <p>{selectedCoin?.name === 'SHIB/USDT' ? formatCurrencySHIB(currency24hData.high24h) : formatCurrency(currency24hData.high24h)}</p> : <p>...</p>}
                  </Text>
                </InfoBlock>
                <InfoBlock>
                  <Title>24h Low</Title>
                  <Text>
                    {currency24hData.low24h && !isNaN(currency24hData.low24h) ? <p>{selectedCoin?.name === 'SHIB/USDT' ? formatCurrencySHIB(currency24hData.low24h) : formatCurrency(currency24hData.low24h)}</p> : <p>...</p>}
                  </Text>
                </InfoBlock>
              </BeltInformationDesktop>
            </BrowserView>
            <MobileView>
              <BeltInformationMobile>
                <Row>
                  <InfoBox>
                    <TitleSmall>Total asset value</TitleSmall>
                    <Info>
                      {parseTotalMoney && <>{parseTotalMoney} USDT </>}
                      <span
                        className={`${
                          percentageChange >= 0 ? "up_state" : "down_state"
                        }`}>
                        {percentageChange >= 0
                          ? parsePercentageChange && (
                              <>+{parsePercentageChange}%</>
                            )
                          : parsePercentageChange && (
                              <>{parsePercentageChange}%</>
                            )}
                      </span>
                    </Info>
                  </InfoBox>
                </Row>
                <Row>
                  <InfoBox>
                    <TitleSmall>Time Left</TitleSmall>
                    <Info>{timeLeft}</Info>
                  </InfoBox>
                </Row>
                <Row>
                  <InfoBox>
                    <TitleSmall>Rank</TitleSmall>
                    <Info>
                      <span
                        className={`${
                          percentageChange >= 0 ? "up_state" : "down_state"
                        }`}>
                        {rank}
                      </span>
                    </Info>
                  </InfoBox>
                </Row>
              </BeltInformationMobile>
            </MobileView>

            <WrapAsset/>
          </FlexWrap>
          <Currency className="hideDesktop">{selectedCoin && (selectedCoin?.name || selectedCoin)}</Currency>
          <GraphFilter
            activeTime={activeTime}
            setActiveTime={setActiveTime}
            chartType={chartType}
            setChartType={setChartType}
          />
          <GraphWrapper>
            <TradingView
              activeTime={activeTime}
              chartType={chartType}
            />
          </GraphWrapper>
        </Wrap>
      </Box>
    </>
  );
};

export default GraphBox;
