import styled from 'styles/styled';
import imgAvatar from '../../../assets/icons/IconBull.svg';

export const NickName = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 28px;
  color: #fff;
  float: left;
  margin: 18px 0 18px 20px;
  @media (max-width: 720px) {
    font-size: 10px;
    position: absolute;
    left: 0;
    right: 0;
    top: 2px;
    margin: 4px auto 0 auto;
    line-height: 15px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    display: none;
  }
`;

export const AvatarIcon = styled.div`
  width: 28px;
  height: 28px;
  float: left;
  background-color: ${({ theme }) => theme.palette.darkMedium};
  border-radius: 100%;
  margin-right: 10px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${imgAvatar});
  @media (max-width: 720px) {
    float: none;
    margin: 0 auto;
    width: 24px;
    height: 24px;
    display: none;
  }
`;
