import React from 'react';

import {
  Wrap,
  Text,
} from './styled';


const WinInformationCryptoSilesia = () => {
  return (
    <Wrap>
      <Text>
        To collect the reward, please, contact Krypto Silesia organizer at <a href="encyklopediakryptowalut@gmail.com">encyklopediakryptowalut@gmail.com</a> using the email address you used to join the tournament and provide your nickname in the message. Congratulations!
      </Text>
    </Wrap>
  );
};

export default WinInformationCryptoSilesia;
