export const formatCurrency = (amount, currency = 'USD') => {
    let formatted = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .format(Number(amount))
    .replace('$', ''); // Removing the dollar sign if you don't need it

    if(formatted > 0 && formatted-1 < 0){
      formatted = Number(formatted).toFixed(3)
    }

    return formatted
  };

export const formatCurrencySHIB = (amount=0) => {
  //return amount.toExponential(2)
  return amount.toFixed(8)
  
  
};
  

export const getCookieDomainFromUrl = (url) => {
  try {
    const urlObj = new URL(url);
    const hostname = urlObj.hostname;
    return hostname.startsWith('www.') ? `.${hostname.slice(4)}` : `.${hostname}`;
  } catch (error) {
    console.error('Invalid URL:', url);
    return '';
  }
};
