import { createGlobalStyle } from 'styles/styled';
import RobotoMedium from './fonts/Roboto-Medium.ttf';
import RobotoLight from './fonts/Roboto-Light.ttf';
import RobotoRegular from './fonts/Roboto-Regular.ttf';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoRegular}) format('truetype');
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoLight}) format('truetype');
    font-weight: ${({ theme }) => theme.fontWeight.light};
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoMedium}) format('truetype');
    font-weight: ${({ theme }) => theme.fontWeight.heavy};
    font-style: normal;
    font-display: auto;
  }

  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
  }

  * {
    -webkit-overflow-scrolling: touch;
    scrollbar-color: ${({ theme }) => theme.palette.grayMedium} transparent;
    scrollbar-width: 'thin';

    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.palette.grayMedium};
    }

    &::-webkit-scrollbar-thumb:vertical {
      min-height: 5vw;
    }

    &::-webkit-scrollbar-thumb:horizontal {
      min-width: 5vh;
    }
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    font-family: 'Roboto';
    font-weight: ${({ theme }) => theme.fontWeight.light};
    line-height: 1;
    background-color: ${({ theme }) => theme.palette.dark};
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  input {
    -webkit-border-radius: 0;
    border-radius: 0;
    filter: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  /* remove background color for autocomplete */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
    -webkit-transition-delay: 9999s;
  }

  :-moz-autofill, :-moz-autofill-preview {
    filter: grayscale(21%) brightness(88%) contrast(161%) invert(10%) sepia(40%) saturate(206%);
  }

  b, strong {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  a {
    text-decoration: none;
  }
`;

export default GlobalStyle;
