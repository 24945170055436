import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthenticationWrapper from 'components/layout/AuthenticationWrapper';
import { GAME, HOME } from 'constants/routes';
import { ButtonFilled } from 'components/ui/Button';
import { register } from 'api/authService';
import * as yup from 'yup';
import Cookies from 'js-cookie';
import { getCookieDomainFromUrl } from 'utils';

import {
  Title,
  Text,
  IntroHead,
  StyledInput,
  BottomWrapper,
} from './styled';

const Register = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const domain = getCookieDomainFromUrl(process.env.REACT_APP_LANDING_PAGE);


  const RegisterSchema = yup.object().shape({
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('Email is required'),
    username: yup
      .string()
      .min(4, 'Username must be at least 3 characters long')
      .required('Username is required'),
    password: yup
      .string()
      .min(6, 'Password must be at least 6 characters long')
      .required('Password is required'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const handleSubmit = async () => {
    const intendedTournamentId = Cookies.get('intendToJoinTournament');

    setError(null);
    try {
      await RegisterSchema.validate({ email, username, password, confirmPassword });
      await register(username, email, password);

      if (intendedTournamentId) {
        // Redirect to the specific tournament page
        Cookies.remove('intendToJoinTournament', { path: '/', domain });
        localStorage.removeItem('intendToJoinTournament');

        setTimeout(() => {
            window.location.href = GAME.replace(':id', intendedTournamentId);
        }, 100);
        
      } else {
        navigate(HOME);
      }
    } catch (error: unknown) {
      console.error('Registration error:', error);
      if (error instanceof yup.ValidationError) {
        setError(error.message);
      } else {
        const err = error as { error?: { message?: string } }; // Type assertion
        setError(err.error ? err.error.message ?? 'An unexpected error occurred' : 'An unexpected error occurred');
      }
    }
  };

  return (
    <AuthenticationWrapper>
      <IntroHead>
        <Title>Create a free account!</Title>
        <Text>Welcome to Trading Battles</Text>
      </IntroHead>
        <StyledInput label="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} />
        <StyledInput label="Nickname" value={username} onChange={(e) => setUsername(e.target.value)} />
        <StyledInput label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <StyledInput
          label="Confirm Password"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          errorText={error}
        />
        <BottomWrapper>
          <ButtonFilled type="submit" onClick={handleSubmit}>Register</ButtonFilled>
          <Text>
            Already registered? <Link to={HOME}>Log in</Link>
          </Text>
        </BottomWrapper>
    </AuthenticationWrapper>
  );
};

export default Register;
