import React, { useEffect, useState, useContext } from 'react';
import { requestTradingData } from 'api/socketService';
import { formatCurrency } from 'utils';
import SocketContext from 'utils/hooks/useSocket';
import { useSocket } from 'utils/hooks/useSocket';
import { useWalletData } from 'context/WalletDataContext';

import {
  Wrap,
  Tab,
  Button,
  ListHistory,
  BeltTop,
  Currency,
  DateInfo,
  Row,
  Amount,
  ValueInfo,
  StatusInfo,
  ListPerformance,
  RowText,
  RowLargeText,
  Load,
  LoadWrap,
} from './styled';

type Trade = {
  id: number;
  trading_pair: string;
  amount: number;
  price: number;
  market_value: number;
  type: string;
  createdAt: string;
  updatedAt: string;
};

const TradeHistoryMobile = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const handleTab1 = () => { setActiveTab("tab1"); };
  const handleTab2 = () => { setActiveTab("tab2"); };
  const [tradingData, setTradingData] = useState<Trade[]>([]);
  const { tournamentDetails } = useSocket();
  const { walletData } = useWalletData();
  const {allCoinsValues } = useContext(SocketContext);

  function calculatePerformance(data, selectedCoinPrice) {
    let totalCoins = 0;
    let totalCost = 0;
    let weightedPriceSum = 0
    let totalAmountBought = 0;

    data?.forEach(trade => {
      if (trade.type === 'buy') {
        totalCoins += trade.amount;
        totalCost += trade.price;
        weightedPriceSum += trade.amount * trade.market_value;
        totalAmountBought += trade.amount;
      } else if (trade.type === 'sell') {
        totalCoins -= trade.amount;
        totalCost -= trade.price;
      }
    });

    const avgPrice = weightedPriceSum / totalAmountBought;
    const currentValue = totalCoins * selectedCoinPrice; // Current total value in USDT
    const plUSDT = currentValue - totalCost
    const plPercent = (currentValue*100 / totalCost) - 100;

    return {
      totalCoins: totalCoins,
      totalCost: totalCost,
      avgPrice: avgPrice,
      currentValue: currentValue,
      plUSDT: plUSDT,
      plPercent: plPercent
    };
  }

  const updateTrading = (data) => {
    if (data.success) {
      setTradingData(data.data.trading);
    } else {
      console.error('Error fetching trading data:');
    }
  };

  const fetchTradingData = () => {
    const tournamentId = tournamentDetails?.data?.id;
    if (tournamentId) {
      requestTradingData(tournamentId, updateTrading);
    }
  };

  const [pairTradesData, updatePairTradesData] = useState(null)
  const [currentWalletData, updateCurrentWalletData] = useState(null)

  if(walletData?.length){
    if(currentWalletData?.length){
      walletData.forEach((element, i) => {
        if(element?.amount !== currentWalletData[i]?.amount){
          updateCurrentWalletData(walletData)
        }
      });
    }else{
      updateCurrentWalletData(walletData)
    }
  }

  let tradingPairWithAmount = walletData.filter(obj => obj.amount > 0 && obj.ticker.short_name !== 'USDT');

  useEffect(()=>{
    fetchTradingData();
  },[currentWalletData])

  useEffect(() => {
    const pairTradesDataTemp = {};
    const pairTradesGroups = {};
    tradingData.forEach(trade => {
      const pair = trade.trading_pair;
      if (!pairTradesGroups[pair]) {
        pairTradesGroups[pair] = [];
      }
      pairTradesGroups[pair].push(trade);
      for (let key in pairTradesGroups) {
        let shortName = key.split('/')[0].toLowerCase();
        let pair = pairTradesGroups[key]
        if(allCoinsValues && allCoinsValues[shortName]){
          let data = calculatePerformance(pair, allCoinsValues[shortName].last);
          pairTradesDataTemp[key] = data
        }
      }
  });
  updatePairTradesData(pairTradesDataTemp)
}, [allCoinsValues, tradingData]);

  return (
    <Wrap>
      <Tab>
        <Button className={activeTab === "tab1" ? "active" : ""} onClick={handleTab1}>Trade History</Button>
        <Button className={activeTab === "tab2" ? "active" : ""} onClick={handleTab2}>Asset Performance</Button>
      </Tab>

      {activeTab === "tab1" ?
        <>
          {tradingData.length> 0 ?
            <ListHistory>
              {tradingData.map(trade => (
                <li key={trade.id}>
                  <BeltTop>
                    <Currency>{trade.trading_pair}</Currency>
                    <DateInfo>{new Date(trade.createdAt).toLocaleString()}</DateInfo>
                  </BeltTop>
                  <Row>
                    <Amount><span>Amount</span> {trade.amount.toFixed(8)}</Amount>
                    <ValueInfo><span>Value</span> {formatCurrency(trade.price)} USDT</ValueInfo>
                  </Row>
                  <Row>
                    <Amount><span>Price</span> {formatCurrency(trade.market_value)}</Amount>
                    <StatusInfo className={trade.type === 'buy' ? 'up_state' : 'down_state'}>
                      {trade.type.charAt(0).toUpperCase() + trade.type.slice(1).toLowerCase()}
                    </StatusInfo>
                  </Row>
                </li>
              ))}
            </ListHistory>
          : <Load><LoadWrap/></Load> }
        </>
        :
        <ListPerformance>
          <ListPerformance>
          {tradingPairWithAmount.map(pair => (
             <li key={pair.id}>
             <div>
               <RowLargeText>
                 <span>{pair.ticker.short_name}</span>
                 {formatCurrency(pairTradesData[`${pair.ticker.short_name}/USDT`]?.currentValue)} USDT
               </RowLargeText>
               <RowText>
                 <span>Amount</span>
                 {pairTradesData[`${pair.ticker.short_name}/USDT`]?.totalCoins.toFixed(8)} {pair.ticker.short_name}
               </RowText>
               <RowText>
                 <span>Avg price</span>
                 {formatCurrency(pairTradesData[`${pair.ticker.short_name}/USDT`]?.avgPrice.toFixed(2))} USDT
               </RowText>
               <RowText className={pairTradesData[`${pair.ticker.short_name}/USDT`]?.plPercent >= 0 ? 'up_state' : 'down_state'}>
                 <span>P/L USDT</span>
                 {formatCurrency(pairTradesData[`${pair.ticker.short_name}/USDT`]?.plUSDT)} USDT
               </RowText>
               <RowText className={pairTradesData[`${pair.ticker.short_name}/USDT`]?.plPercent >= 0 ? 'up_state' : 'down_state'}>
                 <span>P/L%</span>
                 {pairTradesData[`${pair.ticker.short_name}/USDT`]?.plPercent.toFixed(2)}%
               </RowText>
             </div>
           </li>

          ))}
          </ListPerformance>
        </ListPerformance>
      }
    </Wrap>
  );
};
export default TradeHistoryMobile;
