import styled from 'styles/styled';

export const LineText = styled.h4`
  text-align: center;
  font-size: 50px;
  background-color: #52D381;
  border-radius: 14px;
  color: #15191E;
  padding: 1px 0;
  font-weight: 900;
  min-width: 120px;
  padding: 20px 0;
  margin: 0 10px;
  @media (max-width: 720px) {
    min-width: 60px;
    font-size: 30px;
    margin: 0 5px;
    padding: 10px 0;
  }
  span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    @media (max-width: 720px) {
      font-size: 10px;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  @media (max-width: 720px) {
    margin: 10px 0 60px 0;
  }
`;
