import React, { useState, useContext, useEffect } from 'react';
import { ButtonFilled } from 'components/ui/Button';
import AllAssets from 'components/Assets/AllAssets';
import MyAssets from 'components/Assets/MyAssets';
import { useTournament } from 'context/TournamentContext';
import SocketContext from 'utils/hooks/useSocket';

import {
  Wrap,
  ButtonWrap,
  AssetsModal,
  AssetsWrap,
  AssetsScroll,
  ButtonClose,
  BeltTop,
} from './styled';

const WrapAsset = () => {

  const { tournamentDetails, tournamentPairList } = useTournament();
  const { setCoin } = useContext(SocketContext);
  const [isActiveMultiAssets, setIsActiveMultiAssets] = useState(false);
  const [isActiveAllAssets, setIsActiveAllAssets] = useState(false);
  const [isActiveMyAssets, setIsActiveMyAssets] = useState(false);

  const tradingPairsList = tournamentPairList

  const handleClickClose = event => {
    setIsActiveMultiAssets(false);
    setIsActiveAllAssets(false);
    setIsActiveMyAssets(false);
  };

  const handleClickAllAssets = event => {
    setIsActiveAllAssets(true);
    setIsActiveMultiAssets(true);
  };

  const handleClickMyAssets = event => {
    setIsActiveMyAssets(true);
    setIsActiveMultiAssets(true);
  };

  const handleClick = (data) => {
    setCoin(data)
    setIsActiveMultiAssets(false);
    setIsActiveAllAssets(false);
    setIsActiveMyAssets(false);
  };


  return (
    <Wrap>
      <ButtonWrap>
        {tradingPairsList.length > 0 && <ButtonFilled onClick={handleClickAllAssets}>All assets</ButtonFilled>}
        <ButtonFilled onClick={handleClickMyAssets}>My assets</ButtonFilled>
      </ButtonWrap>
      {isActiveMultiAssets &&
        <AssetsModal>
          <AssetsWrap>
            <BeltTop>
              <ButtonClose onClick={handleClickClose} />

            </BeltTop>
            <AssetsScroll>
              {isActiveAllAssets && <AllAssets pairsList = {tradingPairsList} onIconClick={handleClick}/> }
              {isActiveMyAssets && <MyAssets onIconClick={handleClick}/> }
            </AssetsScroll>
          </AssetsWrap>
        </AssetsModal>
      }
    </Wrap>
  );
};

export default WrapAsset;
