import { useEffect, useState, useContext, createContext } from 'react';
import { initializeSocket, emitRefreshTournamentRanking, setRefreshTournamentRankingListener, disconnectSocket, getPriceBySelectedCoin,getAllCoinsPrices } from"api/socketService";
import { useTournament } from 'context/TournamentContext';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

const getShortName = (coin) =>{
  if (typeof coin === 'string' || coin instanceof String){
    return coin.split('/')[0].toLowerCase()
  }
  if(coin?.name){
    return coin?.name.split('/')[0].toLowerCase()
  }
  if(coin?.attributes){
    return coin?.attributes?.base?.data?.attributes?.short_name.toLowerCase()
  }else{
    return coin?.base?.data?.attributes?.short_name.toLowerCase()
  }
}



export const SocketProvider = ({ children }) => {
  const { id } = useParams();


  const username = localStorage.getItem('username') ?? Cookies.get('username');

  const [allCoins, setAllCoins] = useState();
  const [allCoinsValues, setAllCoinsValues] = useState();

  const [selectedCoin, selectCoin] = useState();
  const [selectedCoinValue, setSelectedCoinValue] = useState();

  const [selectedOHLCVValue, setOHLCVValue] = useState();


  const setCoinsPricesObj = (newData) => {
    setAllCoinsValues(prevState => ({
      ...prevState,
      [newData.key]: newData.data
    }));
  }

  const setCoin = (data) =>{
    if (typeof data === 'string' || data instanceof String){
      selectCoin({'name':`${data}`})
    } else{
      selectCoin(data)
    }
  }

  useEffect(()=>{
    if(allCoinsValues && allCoinsValues[getShortName(selectedCoin)]){
      setSelectedCoinValue(allCoinsValues[getShortName(selectedCoin)])
    }else{
      setSelectedCoinValue(0)
    }

    if(allCoinsValues && allCoinsValues[`${getShortName(selectedCoin)}OHLCV`]){
      setOHLCVValue(allCoinsValues[`${getShortName(selectedCoin)}OHLCV`])
    }

    if(selectedCoin !== undefined){
      sessionStorage.setItem('selectedCoin', JSON.stringify(selectedCoin));
    }
  },[selectedCoin])

  useEffect(()=>{
    if(allCoinsValues && allCoinsValues[getShortName(selectedCoin)]){
      setSelectedCoinValue(allCoinsValues[getShortName(selectedCoin)])
    }
    if(allCoinsValues && allCoinsValues[`${getShortName(selectedCoin)}OHLCV`]){
      setOHLCVValue(allCoinsValues[`${getShortName(selectedCoin)}OHLCV`])
    }
  },[allCoinsValues])


  useEffect(()=>{
    if(allCoins?.length){
      getAllCoinsPrices(allCoins, setCoinsPricesObj)

      if (sessionStorage.hasOwnProperty("selectedCoin")) {
        const savedVariable = sessionStorage.getItem('selectedCoin');

        setCoin(JSON.parse(savedVariable))
      }else{
        if(selectedCoin === undefined){
          setCoin(allCoins[0].attributes)
        }
      }

    }
  },[allCoins])


  const [socket, setSocket] = useState(null);
  const [tournamentRanking, setTournamentRanking] = useState({});
  const [socketConnected, setSocketConnected] = useState(false);
  const { tournamentDetails } = useTournament();
  const [rank, setRank] = useState(null);


  useEffect(() => {
    const initSocket = async () => {
      const token = localStorage.getItem('jwt') ?? Cookies.get('jwt');
      const tournamentId = tournamentDetails?.data?.id || id;

      const initializedSocket = await initializeSocket(token, tournamentId, () => setSocketConnected(true));

      setSocket(initializedSocket);
      emitRefreshTournamentRanking();

      setRefreshTournamentRankingListener(tournamentId, handleRefreshTournamentRanking);
    };

    initSocket();

    return () => {
      disconnectSocket();
    };
  }, [tournamentDetails]);



  useEffect(()=>{
    getRank();
  },[tournamentRanking])

  const getRank = () => {
    const usernames = Object.keys(tournamentRanking);
    const rank = usernames.indexOf(username) + 1;
  
    setRank(rank);
  };

  // Define the handler for refreshTournamentRanking
  const handleRefreshTournamentRanking = (data) => {
    if(data && Object.keys(data).length > 0){
      setTournamentRanking(data);
    }
  };

  const value = {
    socket,
    tournamentRanking,
    rank,
    tournamentDetails,
    getPriceBySelectedCoin,
    getAllCoinsPrices,
    setAllCoins,
    allCoinsValues,
    setCoin,
    selectedCoin,
    selectedCoinValue,
    selectedOHLCVValue
  };

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

export default SocketContext;
