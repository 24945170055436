import styled from 'styles/styled';

export const WrapperModal = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: rgb(0 0 0 / 80%);
  top: 0;
  z-index: 500;
`;

export const Wrap = styled.div`
  background-color: #000;
  width: 1440px;
  margin: 0 auto;
  max-width: 90%;
  margin-top: 64px;
  padding: 7px 0;
  border-radius: 14px;
  @media (max-width: 720px) {
    width: 460px;
    max-width: 90%;
    margin: 90px auto 0 auto;
  }
`;

export const Content = styled.div`
  background-color: rgb(21, 25, 30);
  padding: 24px 0;
  position: relative;
`;

export const WrapContent = styled.div`
  width: 1200px;
  margin: 0 auto;
  max-width: 95%;
`;

export const Title = styled.h4`
  text-align: center;
  line-height: 38px;
  font-weight: 700;
  font-size: 28px;
  @media (max-width: 720px) {
    font-size: 18px;
  }
`;
