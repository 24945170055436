import styled from 'styles/styled';
import imgAvatar from '../../../assets/icons/IconBull.svg';

export const Box = styled.div`
  background-color: #15191E;
  border-radius: 12px;
  padding: 15px 0;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 95px;
`;

export const Row = styled.div`
  display: flex;
`;

export const IconAvatar = styled.div`
  width: 34px;
  height: 34px;
  background-color: #000;
  border-radius: 100%;
  margin-right: 10px;
  background-image: url(${imgAvatar});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const Text = styled.h3`
  font-size: 14px;
  line-height: 16px;
  color: #EFEFEF;
  font-weight: 700;
  &.up_state {
    color: #52D381;
  }
  &.down_state {
    color: #FF4B4A;
  }
  &.NickName {
    color: #000;
    background-color: #FFD700;
    border-radius: 3px;
    padding: 0 4px;
    display: inline-block;
  }
`;
