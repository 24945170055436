import React from 'react';
import { Link } from 'react-router-dom';
import AuthenticationWrapper from 'components/layout/AuthenticationWrapper';
import { HOME, REGISTER } from 'constants/routes';
import { ButtonFilled } from 'components/ui/Button';

import {
  Title,
  Text,
  IntroHead,
  BottomWrapper,
  StepInfo,
  Step,
} from './styled';

const ResetPasswordSuccess = () => (

  <AuthenticationWrapper>
    <IntroHead>
      <Title>Success</Title>
      <Text>Your password has been changed</Text>
    </IntroHead>

    <BottomWrapper>
      <Link to={HOME}>
        <ButtonFilled>Log in</ButtonFilled>
      </Link>
    </BottomWrapper>

    <StepInfo>
      <Step className="is_active" />
      <Step className="is_active" />
    </StepInfo>

    <BottomWrapper>
      <Text>
        New here? <Link to={REGISTER}>Register now</Link>
      </Text>
    </BottomWrapper>

  </AuthenticationWrapper>
);

export default ResetPasswordSuccess;
