import styled from 'styles/styled';
import img from '../../../assets/bg_results.png';

export const Wrapper = styled.div`
  background-image: url(${img});
  height: 100vh;
  height: 100svh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  padding: 60px 0;
  @media (max-width: 720px) {
    padding: 30px 0;
  }
  &:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    height: 100svh;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4736) 0%, rgba(0, 0, 0, 0.74) 64.9%, rgba(0, 0, 0, 0.74) 84.21%),
    linear-gradient(90deg, rgba(0, 0, 0, 0.44) 0%, rgba(0, 0, 0, 0.286) 15.38%, rgba(0, 0, 0, 0) 100%);
  }
`;

export const PageContent = styled.div`
  z-index: 10;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 120px);
  height: calc(100svh - 120px);
  justify-content: space-evenly;
  min-height: 500px;
  @media (max-width: 720px) {
    height: initial;
  }
  div {
    color: #fff;
  }
`;
