import styled from 'styles/styled';

export const Title = styled.h3`
  font-size: 22px;
  line-height: 38px;
  font-weight: 500;
  margin-bottom: 10px;
  @media (max-width: 720px) {
    font-size: 18px;
    line-height: 22px;
    max-width: 150px;
    margin: 0 auto 10px auto;
  }
`;

export const Text = styled.h5`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin: 0 5px;
  width: 50%;
  text-align: right;
  &:nth-child(2) {
    color: #52D381;
    text-align: left;
  }
  &.AlignCenter {
    text-align: center;
    width: initial;
  }
  @media (max-width: 720px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const Position = styled.h2`
  font-size: 34px;
  line-height: 38px;
  font-weight: 500;
  color: #52D381;
  @media (max-width: 720px) {
    font-size: 20px;
  }
`;

export const SubText = styled.h6`
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  @media (max-width: 720px) {
    font-size: 10px;
  }
`;

export const RowInformation = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContentInformation = styled.div`
  margin-bottom: 20px;
  @media (max-width: 720px) {
    margin-bottom: 10px;
  }
`;
