import React, { createContext, useContext, useState } from 'react';

const TradeUpdateContext = createContext();

export const TradeUpdateProvider = ({ children }) => {
  const [tradeUpdated, setTradeUpdated] = useState(false);

  const triggerTradeUpdate = () => {
    setTradeUpdated(!tradeUpdated);
  };

  return (
    <TradeUpdateContext.Provider value={{ triggerTradeUpdate, tradeUpdated }}>
      {children}
    </TradeUpdateContext.Provider>
  );
};

export const useTradeUpdate = () => {
  return useContext(TradeUpdateContext);
};
