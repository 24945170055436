import styled from 'styles/styled';
import Input from 'components/ui/Input';

export const IntroHead = styled.div`
  margin-bottom: 25px;
  @media (max-width: 720px) {
    margin-bottom: 15px;
  }
`;

export const Title = styled.h2`
  font-size: 28px;
  line-height: 40px;
  text-align: center;
  font-weight: 800;
  color: ${({ theme }) => theme.palette.light};
  @media (max-width: 720px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

export const Text = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.light};
  line-height: 24px;
  min-height: 24px;
  text-align: center;
  @media (max-width: 720px) {
    font-size: 12px;
    line-height: 20px;
    min-height: 20px;
  }
  a {
    color: ${({ theme }) => theme.palette.primary};
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const ErrorText = styled.p`
  color: ${({ theme }) => theme.palette.error};
  margin-bottom: 30px;
`;

export const StyledInput = styled(Input)`
  width: 100%;
  margin-bottom: 5px;
`;

export const BottomWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const ForgotPasswordLink = styled.div`
  margin-bottom: 30px;
  margin-top: -10px;
  a {
    color: #52D381;
    font-size: 14px;
    line-height: 24px;
    min-height: 24px;
    @media (max-width: 720px) {
      font-size: 12px;
      line-height: 20px;
      min-height: 20px;
    }
    &:hover {
      opacity: 0.8;
    }
  }
`;
