import styled from 'styles/styled';

import img from '../../../assets/introDetail.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  height: 100vh;
  width: 1440px;
  max-width: 95%;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.palette.dark};
  @media (max-width: 920px) {
    display: block;
    max-width: 90%;
    margin: 0 auto;
    height: inherit;
    min-height: 100vh;
    min-height: 100svh;
    overflow: hidden;
  }
  &:after {
    content: "";
    position: absolute;
    background-image: url(${img});
    width: 100%;
    height: 100vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
    top: 0;
    @media (max-width: 920px) {
      height: 240px;
    }
  }
`;

export const IntroBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 80px;
  z-index: 10;
  @media (max-width: 1200px) {
      margin-right: 20px;
  }
  @media (max-width: 920px) {
    width: 90%;
    max-width: 550px;
    margin: 0 auto;
    z-index: 10;
    position: relative;
    padding-top: 30px;
    left: -36px;
  }
  @media (max-width: 720px) {
    max-width: 380px;
    left: -11px;
  }
`;

export const IntroContent = styled.div`
  max-width: 450px;
  width: 100%;
  min-height: 100px;
  margin: 0 0% 0 0%;
  display: flex;
  z-index: 10;
  align-items: center;
  @media (max-width: 920px) {
    margin: -25px auto 0 auto;
  }
  @media (max-width: 720px) {
    max-width: 380px;
  }
`;

export const PageContent = styled.div`
  background-color: ${({ theme }) => theme.palette.darkMedium};
  padding: 25px 25px;
  border-radius: 10px;
  position: relative;
  width: 100%;
  z-index: 9;
  @media (max-width: 920px) {
    padding: 35px 20px 20px 20px;
    margin-bottom: 20px;
  }
`;
