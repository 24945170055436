import React from 'react';
import { Link } from 'react-router-dom';
import AuthenticationWrapper from 'components/layout/AuthenticationWrapper';
import { HOME } from 'constants/routes';
import { ButtonFilled } from 'components/ui/Button';

import {
  Title,
  Text,
  IntroHead,
  BottomWrapper,
  StepInfo,
  Step,
} from './styled';

const ForgotPasswordSendEmail = () => (

  <AuthenticationWrapper>
    <IntroHead>
      <Title>Check your mailbox!</Title>
      <Text>We have sent a link that will allow you to reset your password</Text>
    </IntroHead>

    <BottomWrapper>
      <Link to={HOME}>
        <ButtonFilled>Cancel</ButtonFilled>
      </Link>
      <ButtonFilled type="submit" disabled>Next</ButtonFilled>
    </BottomWrapper>

    <StepInfo>
      <Step className="is_active" />
      <Step/>
    </StepInfo>
  </AuthenticationWrapper>
);

export default ForgotPasswordSendEmail;
