import styled from 'styles/styled';
import imgArrow from '../../../assets/icons/angle-left-solid.svg';

export const LinkText = styled.p`
  background-image: url(${imgArrow});
  background-repeat: no-repeat;
  background-position: center left;
  padding-left: 12px;
  color: #fff;
  font-weight: 400;
  font-size: 13px;
  margin: 10px 0;
  display: none;
  @media (max-width: 1000px) {
    display: block;
  }
  &.centerInfo {
    display: block;
    background-image: none;
    padding: 0;
    font-weight: 300;
    margin: 15px 0 0 0;
    font-size: 10px;
    text-transform: uppercase;
  }
`;

export const Aside = styled.div`
  float: right;
  width: 282px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 10px;
  @media (max-width: 1000px) {
    width: 100%;
    height: initial;
    float: none;
    &.hideMobile {
      display: none;
    }
  }
  .TournamentRankingBox {
    max-height: calc(100vh - 237px);
    min-height: 798px;
    ul {
      max-height: calc(100vh - 377px);
      min-height: 500px;
    }
  }
`;

export const LoadFullPage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  height: 100vh;
  justify-content: center;
`;

export const LoadWrap = styled.div`
  display: block;
  width: 32px;
  height: 32px;
  animation: rotation 800ms infinite linear;
  border-radius: 100%;
  box-shadow: 1px 1px 0 1px #52d382;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

export const ColWrap = styled.div`
  float: Left;
  width: calc(100% - 292px);
  position: relative;
  min-height: calc(100vh - 76px);
  min-height: calc(100svh - 76px);
  @media (max-width: 1000px) {
    width: 100%;
    float: none;
  }
  @media (max-width: 720px) {
    min-height: inherit;
  }
  .hide {
    display: none;
  }
  &.oneBox {
    .JoinWrap {
      height: calc(100vh - 74px);
      height: calc(100svh - 74px);
      min-height: 964px;
      @media (max-width: 1000px) {
        min-height: 798px;
        height: calc(100svh - 76px);
      }
      @media (max-width: 720px) {
        min-height: 600px;
        height: calc(100vh - 140px);
        height: calc(100svh - 140px);
      }
    }
  }
  &.twoBox {
    .JoinWrap {
      height: calc(100vh - 237px);
      height: calc(100svh - 237px);
      min-height: 798px;
      @media (max-width: 1000px) {
        min-height: 798px;
        height: calc(100svh - 76px);
      }
      @media (max-width: 720px) {
        min-height: 600px;
        height: calc(100svh - 140px);
      }
    }
    .TradeHistory {
      position: relative;
      bottom: 0;
      @media (max-width: 1000px) {
        display: none;
      }
    }
  }
`;
