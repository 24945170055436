import apiClient from './apiClient';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { postAuthActions, checkUserDetails } from './authService';
import Cookies from 'js-cookie';
import { getCookieDomainFromUrl } from '../utils';
const domain = getCookieDomainFromUrl(process.env.REACT_APP_LANDING_PAGE);

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};


export const UserProvider = ({ children }) => {
  const [jwt, setJwt] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  const getUserDetailsWithTournaments = async () => userDetails;
  

  useEffect(() => {
    const verifyAuthStatus = async () => {
      let jwtToken = Cookies.get('jwt') || localStorage.getItem('jwt');

      if (jwtToken) {
        try {
          const userDetails = await checkUserDetails();
          setIsAuthenticated(true);
          setUserDetails(userDetails);
        } catch (error) {
          console.error('Authentication verification failed:', error);
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
    };

    verifyAuthStatus();
  }, [jwt]);


  useEffect(() => {
    apiClient.interceptors.request.use((config) => {
      if (jwt) {
        config.headers.Authorization = `Bearer ${jwt}`;
      }
      return config;
    }, (error) => {
      return Promise.reject(error);
    });
  }, [jwt]);

  const login = async (identifier, password, callback) => {
    try {
      const response = await apiClient.post('/auth/local', { identifier, password });
      if (response.status === 200) {
        const token = response.data.jwt;
        console.log('Token: ', token);
        setJwt(token);
        localStorage.setItem('userId', response.data.user.id);
        localStorage.setItem('jwt', token);
        localStorage.setItem('username', response.data.user.username);
        localStorage.setItem('email', response.data.user.email);

        // Setting cookies
        Cookies.set('userId', response.data.user.id, { expires: 7, secure: true, sameSite: 'none', domain });
        Cookies.set('jwt', token, { expires: 7, secure: true, sameSite: 'none', domain });
        Cookies.set('username', response.data.user.username, { expires: 7, secure: true, sameSite: 'none', domain });
        Cookies.set('email', response.data.user.email, { expires: 7, secure: true, sameSite: 'none', domain });

        await postAuthActions();

        if (callback) {
          callback(token);
        }
      }
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  };

  const isLoggedIn = isAuthenticated;

  const value = {
    getUserDetailsWithTournaments,
    isLoggedIn,
    jwt,
    login
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
