import styled from 'styles/styled';
import { StyledLink } from 'components/ui/StyledLink/StyledLink';

export const TabBtnMenu = styled(StyledLink)`
  font-size: 14px;
  line-height: 28px;
  font-weight: 700;
  color: #454545;
  min-width: 124px;
  background-color: #090B0E;
  margin-left: 10px;
  text-align: center;
  padding: 2px 10px 0 10px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: block;
  &:hover {
    color: #fff;
    opacity: 1;
  }
  &.Active {
    color: ${({ theme }) => theme.palette.primary};
    background-color: #15191E;
  }
`;

export const MenuBottom = styled.div`
  float: right;
  height: 100%;
  margin: 35px 15px 0 15px;
  @media (max-width: 1280px) {
    float: left;
    clear: both;
    margin: 35px 20px 0 40px;
  }
  @media (max-width: 1280px) {
    display: none;
  }
`;
