import styled from 'styles/styled';

export const Box = styled.div`
  background-color: #15191E;
  border-radius: 12px;
  padding: 15px 0;
  width: 100%;
  margin-bottom: 10px;
  @media (max-width: 720px) {
    padding: 0;
  }
`;

export const Title = styled.h3`
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  padding: 0 10px;
  margin-bottom: 15px;
  min-height: 41px;
  @media (max-width: 1000px) {
    br {
      display: none;
    }
  }
  @media (max-width: 720px) {
    font-size: 12px;
    padding: 8px 10px 8px 0;
    margin-bottom: 0;
  }
`;

export const RowInfo = styled.div`
  margin-bottom: 10px;
`;

export const LineText = styled.h4`
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  color: #000;
  min-height: 20px;
  background-color: #EFEFEF;
  width: 100%;
  padding: 1px 0;
`;

export const TitleSecond = styled.h5`
  text-align: center;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 5px;
  color: #EFEFEF;
  font-weight: 700;
`;

export const BoxContent = styled.div`
  margin-bottom: 15px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const TournamentRank = styled.ul`
  padding: 0 15px 0 20px;
  height: calc(100vh - 543px);
  min-height: 303px;
  overflow-y: scroll;
  margin-right: 12px;
  @media (min-width: 1000px) {
    @media (max-height: 650px) {
      min-height: 193px;
    }
  }
  @media (max-width: 1000px) {
    height: calc(100vh - 457px);
    min-height: 190px;
    max-height: 190px;
  }
  @media (max-width: 720px) {
    height: 190px;
    padding: 0 15px 0 20px;
  }
`;

export const TournamentList = styled.li`
  list-style-type: decimal;
  font-size: 11px;
  color: #EFEFEF;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 4px;
  vertical-align: top;
  margin-left: 15px;
  @media (max-width: 720px) {
    font-size: 11px;
    vertical-align: top;
    line-height: 17px;
  }
  &:first-child {
    .AmountDetails {
      color: #FFD700;
    }
  }
  .is_me {
    .AmountDetails {
      display: block;
    }
  }
`;

export const WrappList = styled.div`
  width: 100%;
  display: inline-block;
  vertical-align: top;
`;

export const TournamentBlet = styled.div`
  display: flex;
  justify-content: space-between;
  &.is_me {
    background-color: #FFD700;
    border-radius: 3px;
    padding: 0 2px;
    color: #000;
  }
`;

export const NickName = styled.h6`
  font-size: 11px;
  line-height: 18px;
  font-weight: 700;
  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: 720px) {
    font-size: 11px;
    line-height: 15px;
  }
`;

export const Percent = styled.h6`
  font-size: 11px;
  line-height: 18px;
  font-weight: 700;
  @media (max-width: 720px) {
    font-size: 11px;
    line-height: 15px;
  }
`;

export const Amount = styled.h6`
  font-size: 11px;
  color: #52D381;
  line-height: 18px;
  font-weight: 700;
  @media (max-width: 720px) {
    font-size: 9px;
    line-height: 15px;
  }
  &.up_state {
    color: #52D381;
  }
  &.down_state {
    color: #FF4B4A;
  }
`;

export const Load = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
`;

export const LoadWrap = styled.div`
  display: block;
  width: 32px;
  height: 32px;
  animation: rotation 800ms infinite linear;
  border-radius: 100%;
  box-shadow: 1px 1px 0 1px #52d382;
  margin-bottom: 20px;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
