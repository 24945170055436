import React, { useState } from 'react';
import BuyBox from 'components/ui/BuyBox';
import SellBox from 'components/ui/SellBox';

import {
  Box,
  Col,
  Wrap,
  Tab,
  Button,
  MobileView,
} from './styled';

const BuySell = (props: { tournamentDetails: any; }) => {
  const { tournamentDetails } = props;
  const [activeTab, setActiveTab] = useState("tab1");
  const handleTab1 = () => { setActiveTab("tab1");};
  const handleTab2 = () => { setActiveTab("tab2");};

  return (
    <Box>
        <Wrap>
          <Tab>
            <Button className={activeTab === "tab1" ? "active" : ""} onClick={handleTab1}>Buy</Button>
            <Button className={activeTab === "tab2" ? "active" : ""} onClick={handleTab2}>Sell</Button>
          </Tab>
          <MobileView>
            {activeTab === "tab1" ? <BuyBox tournamentDetails={tournamentDetails} /> : <SellBox tournamentDetails={tournamentDetails} />}
          </MobileView>
          <Col>
            <BuyBox tournamentDetails={tournamentDetails} />
          </Col>
          <Col>
            <SellBox tournamentDetails={tournamentDetails} />
          </Col>
        </Wrap>
    </Box>
  );
};

export default BuySell;
