import styled from 'styles/styled';
import bgModal from '../../../assets/bg_modal.png';
import bull from '../../../assets/bull_full.svg';
import fireworks from '../../../assets/fireworks.svg';

export const ModalWrap = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0,0,0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  min-width: 800px;
  text-align: center;
`;

export const BullImg = styled.div`
  background-image: url(${bull});
  position: absolute;
  width: 50%;
  height: 330px;
  background-repeat: no-repeat;
  background-position: center right;
  left: calc(-50% + 140px);
  top: -140px;
  background-size: contain;
`;

export const FireworksImg = styled.div`
  background-image: url(${fireworks});
  position: absolute;
  width: 50%;
  height: 330px;
  background-repeat: no-repeat;
  background-position: center;
  right: -25%;
  top: -170px;
  background-size: contain;
  pointer-events: none;
  animation: firework 500ms ease-in;
  @keyframes firework {
    0% {
      opacity: 0.3;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
}
`;

export const ModalArticle = styled.div`
  padding: 30px;
  border-radius: 15px;
  background-color: rgba(255,255,255, 0.10);
  position: relative;
  background-image: url(${bgModal});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  &:after {
    background: linear-gradient(195.19deg, #000000 21.39%, #52D381 96.72%);
    position: absolute;
    content: " ";
    width: calc(100% + 1px);
    height: calc(100% + -1px);
    border-radius: 14px;
    top: 2px;
    left: 0;
    z-index: -1;
  }
  button {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
`;

export const Title = styled.h3`
  font-size: 22px;
  line-height: 38px;
  font-weight: 500;
  margin-bottom: 10px;
`;
