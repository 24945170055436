import React, { ReactNode } from 'react';

import {
  WrapperModal,
  Title,
  Content,
  Wrap,
  WrapContent,
} from './styled';

interface Props {
  children: ReactNode;
  title?: string;
}

const Modal = ({ title, children }: Props) => (
  <WrapperModal>
    <Wrap>
      <Title>{title}</Title>
      <Content>
        <WrapContent>
          {children}
        </WrapContent>
      </Content>
    </Wrap>
  </WrapperModal>
);

export default Modal;
