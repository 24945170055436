import React, { createContext, useContext, useEffect, useState } from 'react';
import { requestWalletData } from 'api/socketService';
import { useSocket } from 'utils/hooks/useSocket';
import SocketContext from 'utils/hooks/useSocket';

const WalletDataContext = createContext();

export const WalletDataProvider = ({ tournamentDetails, children }) => {
    const [walletData, setWalletData] = useState([]);
    const { btcPrice, socket } = useSocket();

    const updateWallets = (data) => {
        if (data?.success) {
            setWalletData(data.data.balances);
        } else if (data.error) {
            console.error('Error fetching wallet data:', data.error);
        }
    };

    const tournamentId = tournamentDetails?.data?.id;

    const update = () => {
        if (tournamentId) {
            requestWalletData(tournamentId, updateWallets);
        }
    };

    const { allCoinsValues } = useContext(SocketContext);

    useEffect(() => {
        let isMounted = true;
        const fetchWalletData = () => {
            if (isMounted && tournamentId) {
                update();
            }
        };

        fetchWalletData();

        return () => {
            isMounted = false;
        };
    }, [socket]);

    const calculateTotalMoney = () => {
        let btcAmount = 0;
        let usdtAmount = 0;

        walletData.forEach(wallet => {
            if (wallet.ticker.short_name === 'BTC') {
                btcAmount = wallet.amount;
            } else if (wallet.ticker.short_name === 'USDT') {
                usdtAmount = wallet.amount;
            }
        });

        return (btcAmount * btcPrice) + usdtAmount;
    };

    const calculateTotalMoneyString = () => {
        let total = 0;
        if(allCoinsValues){
            walletData.forEach(wallet => {
            if(wallet.amount > 0){
                if (wallet.ticker.short_name === 'USDT') {
                   
                    total+= wallet.amount;
                }else{
                    if(wallet.ticker.short_name?.toLowerCase() in allCoinsValues){
                        total+=wallet.amount * allCoinsValues[wallet.ticker.short_name.toLowerCase()].last;
                    }
                    
                }
            }
        });

        }
        return total;
    };

    const calculateAllTotalMoney = () => {
        
        let arr ={};

        if(allCoinsValues){
            walletData.forEach(wallet => {
            let total = 0;
            if(wallet.amount > 0){
                if (wallet.ticker.short_name === 'USDT') {
                    total+= wallet.amount;
                }else{
                    if(wallet.ticker.short_name?.toLowerCase() in allCoinsValues){
                        total+=wallet.amount * allCoinsValues[wallet.ticker.short_name.toLowerCase()].last;
                    }
                    
                }

                
            }
            arr[wallet.ticker.short_name] = total
        });

        }
        return arr;
    };

    const calculateUSDTMoney = () => {
        let total = 0;
            walletData.forEach(wallet => {
            if(wallet.amount > 0){
                if (wallet.ticker.short_name === 'USDT') {
                    total+= wallet.amount;
                }
            }
        });
        return total;
    };

    const totalMoney = calculateTotalMoney();
    const allCoinsTotalMoney = calculateAllTotalMoney();
    const totalMoneyString = calculateTotalMoneyString().toFixed(2);
    const totalUSDTString = calculateUSDTMoney().toFixed(2);

    return (
        <WalletDataContext.Provider value={{ walletData, btcPrice, update, totalMoneyString,totalUSDTString, totalMoney, allCoinsTotalMoney }}>
            {children}
        </WalletDataContext.Provider>
    );
};


export const useWalletData = () => {
    return useContext(WalletDataContext);
};
