import React, { ReactNode } from 'react';
import LogoDetails from '../../../assets/fullbodyEnd.png';
import LogoDetailsMobile from '../../../assets/fullbodyEndMobile.png';

import {
  Wrapper,
  Content,
  Center,
  TopContent,
} from './styled';

interface Props {
  children: ReactNode;
}

const EndWrap = ({ children }: Props) => (
  <Wrapper className="JoinWrap">
    <Center>
      <TopContent>
        <img src={LogoDetails} className="DesktopImg" alt="Img Logo Details" />
        <img src={LogoDetailsMobile} className="MobileImg" alt="Img Logo Details" />
      </TopContent>
      <Content>
        {children}
      </Content>
    </Center>
  </Wrapper>
);

export default EndWrap;
