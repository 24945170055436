import styled from 'styles/styled';
import img from '../../../assets/introDetail.svg';
import imglogout from '../../../assets/icon_logout.svg';
import { StyledLink } from 'components/ui/StyledLink/StyledLink';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  @media (max-width: 1500px) {
    width: 95%;
  }
  @media (max-width: 1000px) {
    overflow-x: clip;
    padding: 0 20px;
    width: 100%;
    max-width: 800px;
  }
  @media (max-width: 720px) {
    padding: 0 10px;
    height: 100vh;
    height: 100svh;
    position: fixed;
    min-height: 575px;
    max-width: 480px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  @media (max-height: 500px) {
    position: relative;
  }
  &:after {
    content: "";
    position: absolute;
    background-image: url(${img});
    width: 100%;
    height: 100vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
    top: 0;
    left: 0;
    opacity: 0.8;
    @media (max-width: 720px) {
      width: 100%;
    }
  }
`;

export const PageContent = styled.div`
  z-index: 10;
  width: 100%;
  div {
    color: #fff;
  }
`;

export const Nav = styled.div`
  width: 100%;
  position: relative;
  z-index: 10;
  .clear {
    clear: both;
  }
`;

export const WrapNav = styled.div`
  padding: 0 0 0 24px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 720px) {
    padding: 0;
    display: inherit;
  }
`;

export const NavLeft = styled.div`
  width: 100%;
  float: left;
  position: relative;
  @media (max-width: 720px) {
    float: none;
  }
`;

export const NavRight = styled.div`
  width: 282px;
  float: right;
  @media (max-width: 1280px) {
    width: initial;
  }
  @media (max-width: 720px) {
    width: initial;
    float: none;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const IconWrapper = styled.div`
  float: left;
  margin: 17px 0 16px 0;
  min-width: 258px;
  position: relative;
  z-index: 100;
  @media (max-width: 1000px) {
    min-width: inherit;
  }
  @media (max-width: 920px) {
    margin: 12px 0;
  }
  @media (max-width: 720px) {
    margin: 5px 0;
  }
  &.isLoggedDetail {
    min-width: inherit;
  }
  .DesktopLogo {
    display: block;
    svg {
      width: 140px;
      height: 28px;
    }
    @media (max-width: 920px) {
      display: none;
    }
  }
  .MobileLogo {
    display: none;
    svg {
      width: 50px;
      height: 38px
    }
    @media (max-width: 920px) {
      display: block;
    }
  }

`;

export const IconLogOut = styled.div`
  background-image: url(${imglogout});
  width: 14px;
  height: 18px;
  margin-left: 5px;
  float: right;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 720px) {
    width: 12px;
    height: 15px;
    margin-left: 5px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const BtnLogout = styled(StyledLink)`
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  margin: 23px auto 22px auto;
  display: table;
  line-height: 19px;
  @media (max-width: 720px) {
    margin: 17px 0;
    line-height: 15px;
    font-size: 11px;
  }
`;
