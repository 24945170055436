import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthenticationWrapper from 'components/layout/AuthenticationWrapper';
import { HOME, FORGOT_PASSWORD_SEND_EMAIL } from 'constants/routes';
import { ButtonFilled } from 'components/ui/Button';
import { forgotPassword } from 'api/authService';
import * as yup from 'yup';

import {
  Title,
  Text,
  IntroHead,
  StyledInput,
  BottomWrapper,
  StepInfo,
  Step,
} from './styled';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

const ForgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is required'),
});

const handleSubmit = async () => {
  setError(null);
  const trimmedEmail = email.trim(); 
  try {
    await ForgotPasswordSchema.validate({ email: trimmedEmail });
    await forgotPassword(trimmedEmail);
    navigate(FORGOT_PASSWORD_SEND_EMAIL);
  } catch (error: unknown) {
    console.error('Forgot Password error:', error);
    if (error instanceof yup.ValidationError) {
      setError(error.message);
    } else {
      const err = error as { error?: { message?: string } }; // Type assertion
      setError(err.error ? err.error.message ?? 'An unexpected error occurred' : 'An unexpected error occurred');
    }
  }
};

  return (
    <AuthenticationWrapper>
      <IntroHead>
        <Title>Recover your password</Title>
        <Text>Enter your Email</Text>
      </IntroHead>

      <StyledInput
        label="E-mail"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        errorText={error}
      />

      <BottomWrapper>
        <Link to={HOME}>
          <ButtonFilled>Cancel</ButtonFilled>
        </Link>
        <ButtonFilled type="submit" onClick={handleSubmit}>Next</ButtonFilled>
      </BottomWrapper>

      <StepInfo>
        <Step className="is_active" />
        <Step/>
      </StepInfo>
    </AuthenticationWrapper>
  );
};

export default ForgotPassword;