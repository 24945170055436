import React from 'react';

import {
  Wrap,
  Text,
} from './styled';


const WinInformation = () => {
  return (
    <Wrap>
      <Text>
        We kindly ask you to contact us via email at <a href="mailto:contact@tradingbattles.com">contact@tradingbattles.com</a> and provide your USDT wallet address (preferebly on Tron network) to collect the reward!
      </Text>
    </Wrap>
  );
};

export default WinInformation;
