import styled from 'styles/styled';

export const Title = styled.h3`
  font-size: 22px;
  line-height: 38px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
  @media (max-width: 720px) {
    font-size: 16px;
    line-height: 22px;
    width: 280px;
    max-width: 90%;
    margin: 0 auto 10px auto;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
  }
`;

export const SubTitle = styled.h4`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
  @media (max-width: 720px) {
    font-size: 14px;
    line-height: 22px;
    width: 280px;
    max-width: 90%;
    margin: 0 auto;
  }
  &.SecondSubTitle {
    font-weight: 300;
    margin-top: 30px;
    @media (max-width: 720px) {
      margin-top: 10px;
    }
  }
`;

export const Text = styled.h5`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin: 0 5px;
  width: 50%;
  text-align: right;
  &:nth-child(2) {
    color: #52D381;
    text-align: left;
  }
  &.AlignCenter {
    text-align: center;
    width: initial;
  }
  @media (max-width: 720px) {
    font-size: 12px;
    line-height: 18px;
  }
  span {
    &.up_state {
      color: #52D381;
    }
    &.down_state {
      color: #FF4B4A;
    }
  }
`;

export const Position = styled.h2`
  font-size: 34px;
  line-height: 38px;
  font-weight: 500;
  color: #52D381;
  @media (max-width: 720px) {
    font-size: 20px;
    line-height: inherit;
    margin: 2px 0;
  }
`;

export const SubText = styled.h6`
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  @media (max-width: 720px) {
    font-size: 10px;
    line-height: inherit;
  }
`;

export const RowInformation = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContentInformation = styled.div`
  margin-bottom: 20px;
  width: 100%;
  @media (max-width: 720px) {
    margin-bottom: 10px;
  }
`;

export const ContentInformationCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  h5 {
    text-align: center;
  }
`;

export const Wrap = styled.div`
  width: 100%;
  button {
    max-width: 348px;
    min-width: 348px;
    margin: 0 auto;
    @media (max-width: 720px) {
      max-width: 240px !important;
      width: 100%;
      min-width: 240px;
    }
  }
`;

export const WinWrap = styled.div`
  margin-bottom: 20px;
  @media (max-width: 720px) {

  }
`;
