import React, { useEffect, useState } from 'react';
import moment from 'moment';

import {
  LineText,
  Row
} from './styled';

type TournamentCountdownProps = {
  date: string;
};

export const TournamentCountdown = ({ date }: TournamentCountdownProps) => {
  const [timeD, setTimeD] = useState('');
  const [timeH, setTimeH] = useState('');
  const [timeM, setTimeM] = useState('');
  const [timeS, setTimeS] = useState('');

  const calculateTimeLeft = () => {
    const now = moment().utc();
    const end = moment.utc(date);
    const duration = moment.duration(end.diff(now));

    const totalDays = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    setTimeD(`${totalDays}`);
    setTimeH(`${hours}`);
    setTimeM(`${minutes}`);
    setTimeS(`${seconds}`);
  };

  useEffect(() => {
    calculateTimeLeft();
    const intervalId = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(intervalId);
  }, [date]);

  return (
    <Row>
      <LineText>{timeD}<span>DAYS</span></LineText>
      <LineText>{timeH}<span>HOURS</span></LineText>
      <LineText>{timeM}<span>MINUTES</span></LineText>
      <LineText>{timeS}<span>SECONDS</span></LineText>
    </Row>
  );
};

export default TournamentCountdown;
