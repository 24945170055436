import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TournamentProvider } from 'context/TournamentContext';
import { useTournament } from 'context/TournamentContext';
import Dashboard from 'screens/Authenticated/Game';
import JoinTournament from 'screens/Authenticated/JoinTournament';


export const TournamentWrapper = ({ children }) => {
  const { id } = useParams();
  let tournamentId = id ? Number(id) : 87;
  // if (tournamentId === 85) {
  //   tournamentId = 87;
  //   window.location.pathname = window.location.pathname.replace('/85', '/87');
  // }
  const tournamentContext = useTournament();

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { tournamentContext });
    }
    return child;
  });

  return (
    <TournamentProvider tournamentId={tournamentId}>
      {childrenWithProps}
    </TournamentProvider>
  );
};

export const GameRouter = () => {
    const { tournamentStatus, joinedStatus, isJoinedToTournamentWithId, tournamentId } = useTournament();
    const [localJoinedStatus, setLocalJoinedStatus] = useState(joinedStatus);

    useEffect(() => {
      const fetchJoinedStatus = async () => {
          const updatedJoinedStatus = await isJoinedToTournamentWithId(tournamentId);
          setLocalJoinedStatus(updatedJoinedStatus);
      };

      fetchJoinedStatus();
  }, [tournamentId, isJoinedToTournamentWithId]);

    if ((tournamentStatus === 'IN_PROGRESS' || tournamentStatus === 'FINISHED') && (joinedStatus || localJoinedStatus)) {
      return <Dashboard />;
    } else {
      return <JoinTournament />;
    }
  };
