import styled from 'styles/styled';
import imgClose from '../../../assets/icons/close_icon.svg';

export const Wrap = styled.div`
  margin-bottom: 8px;
  @media(max-width: 720px) {
    display: block;
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    background-color: #292B2E;
    color: #fff;
    height: 32px;
    min-height: 32px;
    font-size: 14px;
    margin-left: 5px;
    &:hover {
      background-color: #3E4146;
    }
    @media(max-width: 1280px) {
      min-width: inherit;
    }
    @media(max-width: 720px) {
      line-height: 32px;
      width: calc(50% - 9px);
      margin: 0;
    }
  }
`;

export const AssetsModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  height: 100svh;
  background-color: rgba(0,0,0, 0.9);
  z-index: 200;
`;

export const AssetsWrap = styled.div`
  max-width: 1140px;
  width: calc(100% - 20px);
  margin: 29px auto;
  @media(max-width: 720px) {
    max-width: 400px;
  }
`;

export const AssetsScroll = styled.div`
  max-height: calc(100vh - 101px);
  max-height: calc(100svh - 101px);
  min-height: calc(100vh - 101px);
  min-height: calc(100svh - 101px);
  overflow-y: scroll;
`;

export const ButtonClose = styled.button`
  width: 40px;
  height: 40px;
  background-color: #454545;
  border-radius: 150px;
  color: #fff;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${imgClose});
`;

export const BeltTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3px;
`;
