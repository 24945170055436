import React, {useContext} from 'react';
import SocketContext from 'utils/hooks/useSocket';
import { useWalletData } from 'context/WalletDataContext';

import {
  Row,
  NameAssets,
  ContentAssets,
  IconWrap,
  Name,
  ListAssets,
  Status,
  Icon,
  TextInfo,
} from './styled';

const MyAssets = ({onIconClick}) => {
  const { walletData } = useWalletData();
  const { allCoinsValues } = useContext(SocketContext);
  let tradingPairWithAmount = walletData.filter(obj => obj.amount > 0 && obj.ticker.short_name !== 'USDT');
  let tradingPair = tradingPairWithAmount.map(obj => obj.ticker);
  const currency24hData = allCoinsValues;
  
  tradingPair.forEach(pair => {
    pair.name = `${pair.short_name}/USDT`
  });

  return (
    <>
      <Row>
        <NameAssets>Cryptocurrencies</NameAssets>
        <ContentAssets>
          <ul>
            {tradingPair.map((pair, id) => (
              <ListAssets key={id} onClick={()=>onIconClick(pair)}>
                <IconWrap>
                  <Icon $src={`../icons/tradingPair/use/${pair.short_name.toUpperCase()}.svg`}/>
                </IconWrap>
                <Status className={currency24hData[pair.short_name.toLowerCase()]?.change >= 0 ? "up_state" : "down_state"} />
                <Name>{`${pair.short_name}`}</Name>
              </ListAssets>
            ))}
          </ul>

          {tradingPair.length === 0 &&
            <TextInfo>
              Your assets will appear here once you make the first transaction
            </TextInfo>
          }
        </ContentAssets>
      </Row>
    </>
  );
};

export default MyAssets;
