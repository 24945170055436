import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from 'api/authService';
import * as routes from 'constants/routes';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    logout();
    window.location.href = routes.LANDING;
}, [navigate]);

  return <div>Logging out...</div>;
};

export default Logout;
