import styled from 'styles/styled';

export const Box = styled.div`
  background-color: #15191E;
  border-radius: 12px;
  padding: 15px 0;
  width: 100%;
  margin-bottom: 10px;
  left: 0;
  right: 0;
`;

export const Wrap = styled.div`
  max-width: 90%;
  margin: 0 auto;
`;

export const TableWrap = styled.div`
  height: 88px;
  min-height: 88px;
  overflow: auto;
`;

export const Table = styled.table`
  width: 90%;
`;

export const Td = styled.td`
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #EFEFEF;
  text-align: center;
  padding: 0 3px 3px 3px;
  min-width: 70px;
  &.up_state {
    color: #52D381;
  }
  &.down_state {
    color: #FF4B4A;
  }
  &:first-child {
    width: 90px;
  }
`;

export const Th = styled.th`
  font-weight: 600;
  font-size: 11px;
  line-height: 18px;
  color: #696969;
  padding: 0 3px 12px 3px;
  text-align: center;
  position: sticky;
  min-width: 70px;
  top: 0;
  background-color: #15191E;
  &:first-child {
    width: 90px;
  }
`;

export const Tab = styled.div`
  margin-bottom: 15px;
`;

export const Button = styled.button`
  font-size: 12px;
  margin: 0 10px;
  line-height: 20px;
  font-weight: 700;
  color: #696969;
  appearance: none;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  &.active {
    color: #EFEFEF;
    &:after {
      content: " ";
      position: absolute;
      bottom: -2px;
      width: 20px;
      left: 0;
      height: 2px;
      border-radius: 3px;
      background-color: #fff;
    }
  }
`;
