
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthenticationWrapper from 'components/layout/AuthenticationWrapper';
import { HOME, RESET_PASSWORD_SUCCESS } from 'constants/routes';
import { ButtonFilled } from 'components/ui/Button';
import { resetPassword } from 'api/authService';
import * as yup from 'yup';


import {
  Title,
  Text,
  IntroHead,
  StyledInput,
  BottomWrapper,
  StepInfo,
  Step,
} from './styled';

const PasswordReset = () => {
  let params = (new URL(document.location.href)).searchParams;
  const code = encodeURI(params.get("code"))
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  const ResetPasswordSchema = yup.object().shape({
    password: yup
      .string()
      .min(6, 'Password must be at least 6 characters long')
      .required('Password is required'),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password')], 'Passwords must match')
      .required('Confirm Password is required'),
  });

const handleSubmit = async () => {
  setError(null);
  try {
    await ResetPasswordSchema.validate({ password, passwordConfirmation });
    await resetPassword(code, password, passwordConfirmation);
    navigate(RESET_PASSWORD_SUCCESS);
  } catch (error: unknown) {
    console.error('Password Reset error:', error);
    if (error instanceof yup.ValidationError) {
      setError(error.message);
    } else {
      const err = error as { error?: { message?: string } }; // Type assertion
      setError(err.error ? err.error.message ?? 'An unexpected error occurred' : 'An unexpected error occurred');
    }
  }
};

  return (
    <AuthenticationWrapper>
      <IntroHead>
        <Title>Create new Password</Title>
        <Text>Enter new Password for your account</Text>
      </IntroHead>

      <StyledInput
        label="New Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <StyledInput
        label="Confirm Password"
        type="password"
        value={passwordConfirmation}
        onChange={(e) => setPasswordConfirmation(e.target.value)}
        errorText={error}
      />

      <BottomWrapper>
        <Link to={HOME}>
          <ButtonFilled>Cancel</ButtonFilled>
        </Link>
        <ButtonFilled type="submit" onClick={handleSubmit}>Next</ButtonFilled>
      </BottomWrapper>

      <StepInfo>
        <Step className="is_active" />
        <Step className="is_active" />
      </StepInfo>
    </AuthenticationWrapper>
  );
};

export default PasswordReset;
