import styled, { css } from 'styles/styled';
import { TextTransform } from 'types';

interface ButtonProps {
  textTransform?: TextTransform;
  type?: string;
  fontWeight?: string;
  width?: string;
  minWidth?: number;
  margin?: boolean;
}

const baseStyles = css<ButtonProps>`
  min-width: ${({ minWidth }) => minWidth ?? '64'}px;
  width: ${({ width }) => width ?? 'unset'};
  min-height: 50px;
  padding: ${({ theme }) => theme.dimensions.spacingBase10}px;
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight ?? theme.fontWeight.heavy};
  font-size: 15px;
  cursor: pointer;
  transition: ${({ theme }) => theme.ui.transition('all')};
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  margin-right: ${({ margin, theme }) =>
    margin ? `${theme.dimensions.spacingBase16}px` : '0'};
  border-radius: 10px;

  &:disabled {
    cursor: not-allowed;
  }

  @media ${({ theme }) => theme.devices.medium} {
    min-width: ${({ minWidth }) => minWidth ?? '134'}px;
  }
  @media (max-width: 720px) {
    font-size: 15px;
    height: 38px;
    min-height: 38px;
    border-radius: 8px;
  }
`;

export const ButtonFilled = styled.button<ButtonProps>`
  ${baseStyles};
  background-color: ${({ theme }) => theme.palette.primary};
  color: #212121;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary};
    opacity: 0.9;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.palette.grayMedium};
  }
  a {
    color: #212121;
  }
`;

export const ButtonOutlined = styled.button<ButtonProps>`
  ${baseStyles};
  background-color: transparent;
  color: ${({ theme }) => theme.palette.primary};
  border: 1px solid ${({ theme }) => theme.palette.primary};

  &:hover {
    color: ${({ theme }) => theme.palette.secondary};
    border-color: ${({ theme }) => theme.palette.primary};
  }

  &:disabled {
    color: ${({ theme }) => theme.palette.grayMedium};
    border-color: ${({ theme }) => theme.palette.grayMedium};
  }
`;
