import React from 'react';

import {
  MenuBottom,
  TabBtnMenu,
} from './styled';

const Menu = () => {
  return (
    <MenuBottom>
      <TabBtnMenu className="Active" to={'https://tradingbattles.com/#upcoming-tournaments'}>Tournament</TabBtnMenu>
    </MenuBottom>
  );
};

export default Menu;
