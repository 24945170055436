import styled from 'styles/styled';
import { Link } from 'react-router-dom';

// eslint-disable-next-line import/prefer-default-export
export const StyledLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary};
  transition: ${({ theme }) => theme.ui.transition('all')};

  &:hover {
    opacity: 0.7;
  }
`;
