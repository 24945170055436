function timeToLocal(originalTime) {
    const date = new Date(originalTime);
    const offsetInMilliseconds = date.getTimezoneOffset() * 60 * 1000;
    const localTimestamp = originalTime - offsetInMilliseconds;
    return Math.floor(localTimestamp / 1000); // Convert back to seconds
}

export const transformHistoricalData = (data, chartType) => {
    return data.history.map(item => {
      const transformedData = {
        time: timeToLocal(item[0]),
      };
  
      switch (chartType) {
        case 'Line':
        case 'Area':
          transformedData.value = item[4]; // Using close price for Line and Area charts
          break;
        default: // For Bars and Candles
          transformedData.open = item[1];
          transformedData.high = item[2];
          transformedData.low = item[3];
          transformedData.close = item[4];
          transformedData.volume = item[5];
      }
  
      return transformedData;
    });
  };
  
  export const transformLiveData = (data, chartType) => {
    const transformedData = {
      time: timeToLocal(data.data[0]),
    };
  
    switch (chartType) {
      case 'Line':
      case 'Area':
        transformedData.value = data.data[4]; // Using close price for Line and Area charts
        break;
      default: // For Bars and Candles
        transformedData.open = data.data[1];
        transformedData.high = data.data[2];
        transformedData.low = data.data[3];
        transformedData.close = data.data[4];
        transformedData.volume = data.data[5];
    }
  
    return transformedData;
  };
  