import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { logout } from 'api/authService';

import { ReactComponent as LogoSVG } from 'assets/icons/logo.svg';
import { ReactComponent as LogoSVGMobile } from 'assets/icons/mobile_logo.svg';
import IconSvg from 'components/ui/IconSvg';
import Menu from 'components/ui/Menu';
import Banner from 'components/ui/Banner';
import Avatar from 'components/ui/Avatar';
import { LANDING } from 'constants/routes';
import { useUser } from 'api/userService';

import {
  Wrapper,
  PageContent,
  Nav,
  NavLeft,
  NavRight,
  WrapNav,
  IconWrapper,
  IconLogOut,
  BtnLogout,
} from './styled';

interface Props {
  children: ReactNode;
}

const BaseWrapper = ({ children }: Props) => {
const { isLoggedIn } = useUser();
const handleLogoutClick = () => {
  logout();
  // navigate('/login');
};
let button;
if (isLoggedIn) {
  button = <>Logout <IconLogOut></IconLogOut></>;
} else {
  button = <>Log In <IconLogOut></IconLogOut></>;
}
  return (
    <Wrapper>
      <Nav>
        <NavLeft>
          <WrapNav>
            <div>
              <IconWrapper className={isLoggedIn ? "isLoggedDetail" : ""}>
                <Link to={LANDING}>
                  <div className="DesktopLogo">
                    <IconSvg Icon={LogoSVG} />
                  </div>
                  <div className="MobileLogo">
                    <IconSvg Icon={LogoSVGMobile} />
                  </div>
                </Link>
              </IconWrapper>
              {isLoggedIn &&<Avatar />}
            </div>
            <Banner />
            {isLoggedIn &&<Menu />}
            <NavRight>
              <BtnLogout onClick={handleLogoutClick} to={LANDING}>
                {button}
              </BtnLogout>
            </NavRight>
          </WrapNav>
        </NavLeft>
        <div className="clear"></div>
      </Nav>
      <PageContent>{children}</PageContent>
    </Wrapper>
  );
}

export default BaseWrapper;
