import React from 'react';
import { Link } from 'react-router-dom';
import AuthenticationWrapper from 'components/layout/AuthenticationWrapper';
import { LANDING } from 'constants/routes';
import { ButtonFilled } from 'components/ui/Button';
import Cookies from 'js-cookie';
import { getCookieDomainFromUrl } from 'utils';

import {
  Title,
  Text,
  IntroHead,
  BottomWrapper,
} from './styled';

const domain = getCookieDomainFromUrl(process.env.REACT_APP_LANDING_PAGE);
const PageNotFound = () => {
  const intendedTournamentId = localStorage.getItem('intendToJoinTournament') ?? Cookies.get('intendToJoinTournament');

  return (
    <AuthenticationWrapper>
      <IntroHead>
        <Title>404</Title>
        <Text>Oops! Looks like you got lost</Text>
      </IntroHead>

      <BottomWrapper>
        <Link to={LANDING}>
          <ButtonFilled>Take me back</ButtonFilled>
        </Link>
      </BottomWrapper>
    </AuthenticationWrapper>
  );
};

export default PageNotFound;
