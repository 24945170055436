import React, { ReactNode } from 'react';

import {
  Wrapper,
  PageContent
} from './styled';

interface Props {
  children: ReactNode;
}

const ResultsWrapper = ({ children }: Props) => {
  return (
    <Wrapper>
      <PageContent>{children}</PageContent>
    </Wrapper>
  );
}

export default ResultsWrapper;
