import styled from 'styles/styled';

export const Box = styled.div`

`;

export const Title = styled.h3`
  font-size: 52px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  text-shadow: 1px 3px 1px #000;
  margin-bottom: 20px;
  padding: 0 20px;
  @media (max-width: 1200px) {
    font-size: 38px;
  }
  @media (max-width: 720px) {
    font-size: 28px;
  }
`;

export const SubTitle = styled.h3`
  font-size: 32px;
  font-weight: 500;
  color: #000;
  text-align: center;
  background-color: #52D381;
  padding: 15px 0;
  border-radius: 14px;
  margin-bottom: 20px;
  @media (max-width: 720px) {
    font-size: 18px;
  }
  &.inner {
    max-width: 300px;
    margin: 0 auto;
    margin-top: 10px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1640px;
  margin: 0 auto;
  @media (max-width: 720px) {
    flex-direction: column;
    width: 100%;
    margin-bottom: 40px;
  }
`;

export const ContentPhoto = styled.div`
  width: calc(100% - 550px);
  justify-content: center;
  display: flex;
  @media (max-width: 1100px) {
    display: none;
  }
  @media (max-width: 720px) {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
  }
  img {
    width: 100%;
    max-width: 900px;
    max-height: calc(100vh - 280px);
    @media (max-width: 720px) {
      max-width: 400px;
      margin: 0 auto;
      width: 80%;
    }
  }
`;

export const ContentResults = styled.div`
  width: 400px;
  background-color: #000;
  border-radius: 14px;
  position: relative;
  padding-bottom: 30px;
  @media (max-width: 720px) {
    width: 90%;
    max-width: 400px;
  }
  &:after {
    background: linear-gradient(195.19deg, #000000 21.39%, #52D381 96.72%);
    position: absolute;
    content: " ";
    width: calc(100% + 2px);
    height: calc(100% + -1px);
    border-radius: 14px;
    top: 2px;
    left: -1px;
    z-index: -1;
  }
`;

export const LineText = styled.h4`
  text-align: center;
  font-size: 40px;
  background-color: #52D381;
  border-radius: 14px;
  color: #15191E;
  padding: 1px 0;
  font-weight: 900;
  min-width: 120px;
  padding: 20px 60px;
  display: table;
  margin: 20px auto 0 auto;
  @media (max-width: 720px) {
    font-size: 18px;
    margin: 20px auto 40px auto;
  }
`;

export const BoxContent = styled.div`
  margin-bottom: 15px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const TournamentRank = styled.ul`
  padding: 0 20px 0 10px;
  max-height: calc(100vh - 470px);
  min-height: 200px;
  overflow-y: scroll;
  margin-right: 12px;
  max-width: 320px;
  margin: 0 auto;
  @media (max-width: 720px) {
    width: 90%;
  }
`;

export const TournamentList = styled.li`
  list-style-type: decimal;
  font-size: 11px;
  color: #EFEFEF;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 4px;
  vertical-align: top;
  margin-left: 15px;
  @media (max-width: 720px) {
    font-size: 11px;
    vertical-align: top;
    line-height: 17px;
  }
  &:first-child {
    .Name_500 {
      &:after {
        content: " (300 USDT)";
        color: #FFD700;
      }
    }
    .Name_more,
    .Name {
      &:after {
        content: " (500 USDT)";
        color: #FFD700;
      }
    }
    .Name_87 {
      &:after {
        content: " (150 USDT)";
        color: #FFD700;
      }
    }
  }
  &:nth-child(2) {
    .Name_500 {
      &:after {
        content: " (150 USDT)";
        color: #FFD700;
      }
    }
    .Name_more,
    .Name {
      &:after {
        content: " (300 USDT)";
        color: #FFD700;
      }
    }
    .Name_87 {
      &:after {
        content: " (100 USDT)";
        color: #FFD700;
      }
    }
  }
  &:nth-child(3) {
    .Name_500 {
      &:after {
        content: " (50 USDT)";
        color: #FFD700;
      }
    }
    .Name_more,
    .Name {
      &:after {
        content: " (200 USDT)";
        color: #FFD700;
      }
    }
    .Name_87 {
      &:after {
        content: " (50 USDT)";
        color: #FFD700;
      }
    }
  }
  &:nth-child(4) {
    .Name_87 {
      &:after {
        content: " (40 USDT)";
        color: #FFD700;
      }
    }
  }
  &:nth-child(5) {
    .Name_87 {
      &:after {
        content: " (30 USDT)";
        color: #FFD700;
      }
    }
  }
  &:nth-child(1n+6) {
    .Name_87 {
      &:after {
        content: " (25 USDT)";
        color: #FFD700;
      }
    }
  }
  &:nth-child(1n+11) {
    .Name_87 {
      &:after {
        content: " (In-kind merchandising)";
        color: #FFD700;
      }
    }
  }
  &:nth-child(1n+21) {
    .Name_87 {
      background-color: transparent;
      &:after {
        display: none;
      }
    }
  }

  &:nth-child(1n+4) {
    .Name_more {
      &:after {
        content: " (50 PLN)";
        color: #FFD700;
      }
    }
  }
  &:nth-child(1n+34) {
    .Name_more {
      background-color: transparent;
      &:after {
        display: none;
      }
    }
  }
`;

export const WrappList = styled.div`
  width: 100%;
  display: inline-block;
  vertical-align: top;
`;

export const TournamentBlet = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NickName = styled.h6`
  font-size: 11px;
  line-height: 18px;
  font-weight: 700;
  max-width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: 720px) {
    font-size: 11px;
    line-height: 15px;
  }
`;

export const Percent = styled.h6`
  font-size: 11px;
  line-height: 18px;
  font-weight: 700;
  @media (max-width: 720px) {
    font-size: 11px;
    line-height: 15px;
  }
`;

export const Amount = styled.h6`
  font-size: 11px;
  color: #52D381;
  line-height: 18px;
  font-weight: 700;
  @media (max-width: 720px) {
    font-size: 9px;
    line-height: 15px;
  }
  &.up_state {
    color: #52D381;
  }
  &.down_state {
    color: #FF4B4A;
  }
`;

export const Load = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
`;

export const BeltBottom = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 30px;
  color: #fff;
  @media (max-width: 1100px) {
    margin-top: 40px;
  }
  @media (max-width: 720px) {
    margin-top: 0;
  }
  p {
    @media (max-width: 720px) {
      font-size: 18px;
    }
  }
`;

export const LoadWrap = styled.div`
  display: block;
  width: 32px;
  height: 32px;
  animation: rotation 800ms infinite linear;
  border-radius: 100%;
  box-shadow: 1px 1px 0 1px #52d382;
  margin-bottom: 20px;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
