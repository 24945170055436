import React from 'react';
import EndTournament from 'components/Join/EndTournament';

import {
  ModalWrap,
  ModalContent,
  ModalArticle,
  Title,
  BullImg,
  FireworksImg,
} from './styled';


const Modal = (props: { tournamentDetails: any; }) => {
  const { tournamentDetails } = props;

  return (
    <ModalWrap>
      <ModalContent>
        <Title>Tournament summary</Title>
        <ModalArticle>
          <BullImg/>
          <FireworksImg/>

          <EndTournament tournamentDetails={tournamentDetails} />
        </ModalArticle>
      </ModalContent>
    </ModalWrap>
  );
};

export default Modal;
