import React from 'react';
import { ButtonFilled } from 'components/ui/Button';
import { useWalletData } from 'context/WalletDataContext';
import { useSocket } from 'utils/hooks/useSocket';
import { formatCurrency } from 'utils';
import { useTournament } from 'context/TournamentContext';

import {
  ContentInformation,
  RowInformation,
  Text,
  Position,
  SubText,
  Title,
} from './styled';

  const EndTournament = (props: { tournamentDetails: any; }) => {
  const { tournamentDetails } = props;
  const attributes = tournamentDetails?.data?.attributes;
  const participantsCount = attributes?.participants?.data?.length;
  const rewardPool = tournamentDetails?.data?.attributes?.reward_pool;
  const rewardPoolCurrency = tournamentDetails?.data?.attributes?.reward_pool_currency || 'USDT';
  const displayRewardPool = rewardPool > 0 ? rewardPool + ' ' + rewardPoolCurrency: '---';
  const { totalMoney, totalUSDTString } = useWalletData();
  const { rank } = useSocket();
  const startDate = new Date(attributes.start_at).toLocaleDateString();
  const endDate = new Date(attributes.end_at).toLocaleDateString();
  const initialMoney = attributes.amount_start || 1;
  const percentageChange = ((totalMoney - initialMoney) / initialMoney) * 100;
  const parseTotalMoney = formatCurrency(totalUSDTString);
  const parsePercentageChange = (percentageChange.toFixed(2));

  const {tournamentPairList } = useTournament();
  //const [tradingPair, setTradingPair] = useState([]);
  const tradingPair = tournamentPairList;

  // useEffect(() => {
  //   const checkTournamentPairList = async () => {
  //     const tournamentId = tournamentDetails?.data?.id;
  //     if (tournamentId) {
  //       try {
  //         const apiData = await getCryptoTradingPairList(tournamentId);
  //         setTradingPair(apiData);
  //       } catch (error) {
  //         console.error("Error fetching tournament pair id:", error);
  //       }
  //     }
  //   };

  //   checkTournamentPairList();
  // }, [tournamentDetails?.data?.id]);



  return (
    <>
      <Title>{attributes?.name}</Title>
      <ContentInformation>
        <RowInformation>
          <Text>Market:</Text>
          <Text>Cryptocurrency</Text>
        </RowInformation>
        <RowInformation>
          <Text>Trading pair:</Text>
          <Text>{tradingPair.length === 0 ? `` : tradingPair?.length > 1 ? `Multi assets` : tradingPair[0]?.attributes?.name}</Text>
        </RowInformation>
        <RowInformation>
          <Text>Tournament time:</Text>
          <Text>{startDate} to {endDate}</Text>
        </RowInformation>
        <RowInformation>
          <Text>Result:</Text>
          <Text>{parseTotalMoney}</Text>
        </RowInformation>
        <RowInformation>
          <Text>Result %:</Text>
          <Text>{parsePercentageChange}</Text>
        </RowInformation>
        {
          rank === 1 &&
          <RowInformation>
            <Text>Reward:</Text>
            <Text>{displayRewardPool}</Text>
          </RowInformation>
        }
      </ContentInformation>

      <ContentInformation>
        <Text className="AlignCenter">Your position:</Text>
        <Position>{rank}</Position>
        <SubText>(out of {participantsCount} participants)</SubText>
      </ContentInformation>

      <ButtonFilled type="button">Continue</ButtonFilled>
    </>
  );
};

export default EndTournament;
