import React, { useEffect, useRef, useState, useContext } from 'react';
import { createChart } from 'lightweight-charts';
import { emitChangeOHLCVTimeframe } from 'api/socketService';
import { transformHistoricalData, transformLiveData } from 'utils/transformData';
import SocketContext from 'utils/hooks/useSocket';
import { formatCurrency, formatCurrencySHIB } from "utils";

const TradingView = ({ activeTime, chartType}: { activeTime: string; chartType: string }) => {
  const chartContainerRef = useRef(null);
  const seriesRef = useRef(null);
  const seriesPriceLineRef = useRef(null);
  const {selectedOHLCVValue, selectedCoinValue, selectedCoin } = useContext(SocketContext);
  const [price, setPrice] = useState(0);
  const [coinOHLCV, setCoinOHLCV] = useState(0);
  const [currentPair, setcurrentPair] = useState('');
  const [lastHistoricalData, setLastHistoricalData] = useState(1)

  let currentPairName = selectedCoin && (selectedCoin?.name || selectedCoin)

  if(selectedCoinValue.last !== price){
    setPrice(selectedCoinValue.last)
  }

  if(selectedOHLCVValue !== coinOHLCV){
    setCoinOHLCV(selectedOHLCVValue)
  }

  if(currentPair !== currentPairName){
    setcurrentPair(currentPairName)
  }

  let  myPriceFormatter

  if(currentPairName === 'SHIB/USDT'){
    myPriceFormatter = (p)=>formatCurrencySHIB(p);
  }else{
    myPriceFormatter = (p)=>formatCurrency(p)
  }

  const createSeries = (chart, chartType) => {
    if (!chart || !chartType) {
      console.error('Chart or chartType is undefined', chart, chartType);
      return;
    }

    let series;
    switch(chartType) {
      case 'Line':
        series = chart.addLineSeries();
        break;
      case 'Bars':
        series = chart.addBarSeries();
        break;
      case 'Area':
        series = chart.addAreaSeries();
        break;
      case 'Candles':
        series = chart.addCandlestickSeries();
        break;
      default:
          console.error('Invalid chartType', chartType);
          return;
    }

    series.applyOptions({
      lastValueVisible: false, // Disable automatic last price label
    });

    const priceLine = series.createPriceLine({
      price: price,
      color: 'rgba(4, 111, 232, 1)',
      lineWidth: 2,
      axisLabelVisible: true,
    });

    series.priceScale().applyOptions({
      borderColor: "#515151",
      price: price,
    })

    seriesRef.current = series;
    seriesPriceLineRef.current = priceLine; // Store the price line reference

  };

  useEffect(() => {
    const chart = createChart(chartContainerRef.current, {
      layout: {
        textColor: '#ffffff',
        background: { color: 'transparent' },
      },
      timeScale: {
        borderColor: "#515151",
        timeVisible: true,
        secondsVisible: true,
        rightOffset: 15
      },
      rightPriceScale: {
        borderColor: "#515151",
      },
      grid: {
        vertLines: {
          color: "#515151",
        },
        horzLines: {
          color: "#515151",
        }
      },
      localization: {
        priceFormatter: myPriceFormatter
      },
    });

    createSeries(chart, chartType);

    const handleResize = () => {
      chart.applyOptions({
        width: chartContainerRef.current.clientWidth,
        height: chartContainerRef.current.clientHeight,
      });
      chart.timeScale().fitContent();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      chart.remove();
      window.removeEventListener('resize', handleResize);
    };
  }, [chartType, currentPair]);


  useEffect(() => {
    if (currentPair) {
      emitChangeOHLCVTimeframe({
        timeframe: activeTime,
        currencyPair: currentPair,
      }, (response) => {
        if (response.success && seriesRef.current) {
          const data = transformHistoricalData(response.data, chartType);
          setLastHistoricalData(data[data.length-1].time)
          seriesRef.current.setData(data);
          seriesPriceLineRef.current.applyOptions({
            price: price,
          });
        }
      });
    }

  }, [activeTime, chartType, currentPair]);


  useEffect(() => {
    if (coinOHLCV && seriesRef.current) {

      const transformedData = transformLiveData(coinOHLCV, chartType);
      if(transformedData.time > lastHistoricalData){
        seriesRef.current.update(transformedData);
      }

    }
  }, [coinOHLCV, chartType]);



  useEffect(() => {
    if (seriesPriceLineRef.current && price) {
      seriesPriceLineRef.current.applyOptions({
        price: price,
      });
    }
  }, [chartType, price]);

  return <div ref={chartContainerRef} className="GraphStyles" style={{ height: '100%', width: '100%' }} />;
};

export default TradingView;
