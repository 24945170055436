import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTournament } from 'context/TournamentContext';
import { LANDING } from 'constants/routes';
import { WalletDataProvider } from 'context/WalletDataContext';
import { TradeUpdateProvider } from 'context/TradeUpdateContext';

import BaseWrapper from 'components/layout/BaseWrapper';
import UserState from 'components/dashboard/UserState';
import TournamentRanking from 'components/dashboard/TournamentRanking';
import JoinPage from 'components/Join/JoinPage';
import JoinEnd from 'components/Join/JoinEnd';
import JoinWrap from 'components/layout/JoinWrap';
import EndWrap from 'components/layout/EndWrap';
import TradeHistory from 'components/dashboard/TradeHistory';
import Footer from 'components/ui/Footer';

import {
  Aside,
  LinkText,
  LoadFullPage,
  LoadWrap,
  ColWrap,
} from './styled';


const JoinTournament = () => {
  const { joinedStatus, tournamentDetails, tournamentStatus } = useTournament();
  const result = joinedStatus ? "twoBox" : "oneBox";
  const [isloadPage, setLoadPage] = useState<Promise<boolean | void> | boolean>(true);
    useEffect(() => {

        const timeout = setTimeout(() => setLoadPage(false), 1500)
        return () => clearTimeout(timeout);
    }, [])
    if (isloadPage) {
        return <LoadFullPage><LoadWrap></LoadWrap></LoadFullPage>;
    };

  return (
    <BaseWrapper>
      <Link to={LANDING}>
        <LinkText>BACK</LinkText>
      </Link>
      <WalletDataProvider tournamentDetails={tournamentDetails}>
        <ColWrap className={result}>
          {(tournamentStatus === 'FINISHED') ? (
            <EndWrap>
              <JoinEnd tournamentDetails={tournamentDetails} />
            </EndWrap>
          ) :
            <JoinWrap>
              <JoinPage tournamentDetails={tournamentDetails} />
            </JoinWrap>
          }
          {joinedStatus ? (
            <TradeUpdateProvider>
              <TradeHistory tournamentDetails={tournamentDetails} />
            </TradeUpdateProvider>
          ) : null}
        </ColWrap>

        <Aside className="hideMobile">
          <TournamentRanking />
          <UserState tournamentDetails={tournamentDetails} />
          <Footer />
        </Aside>
      </WalletDataProvider>
    </BaseWrapper>
  );
}
export default JoinTournament;
