import React, { useEffect, useState } from 'react';
import { TournamentCountdown } from '../TournamentCountdown/TournamentCountdown';
import { useSocket } from 'utils/hooks/useSocket';
import { formatCurrency } from 'utils';
import { useWalletData } from 'context/WalletDataContext';
import Cookies from 'js-cookie';
import { useTournament } from 'context/TournamentContext';
import { BrowserView } from 'react-device-detect';
import {
  Box,
  BoxContent,
  Title,
  RowInfo,
  LineText,
  TitleSecond,
  TournamentRank,
  TournamentList,
  NickName,
  Percent,
  Amount,
  TournamentBlet,
  WrappList,
  LoadWrap,
  Load,
} from './styled';
import { getTournamentRanking } from 'api/tournamentService';

type Balance = {
  BTC: number;
  USDT: number;
};

type TournamentRankingData = {
  [player: string]: Balance;
};

type TournamentDetails = {
  data?: {
    id?: number;
    attributes?: {
      name?: string;
      end_at?: string;
      start_at?: string;
      amount_start?: number;
      market_type?: any;
    };
  };
};

const TournamentRanking = () => {
  const { tournamentRanking, tournamentDetails } = useSocket() as { tournamentRanking: TournamentRankingData, tournamentDetails: TournamentDetails};
  const username = localStorage.getItem('username') ?? Cookies.get('username');
  const { tournamentStatus, tournamentPairList } = useTournament();
  const { totalMoneyString } = useWalletData();
  const [initialMoney, setInitialMoney] = useState(0);
  const tradingPair = tournamentPairList
  const [localTournamentRanking, setLocalTournamentRanking] = useState<TournamentRankingData | null | Promise<any>>(null);

  useEffect(() => {
    const checkTournamentRanking = async () => {
      const tournamentId = tournamentDetails?.data?.id;
      const initialMoney = tournamentDetails?.data?.attributes?.amount_start || 1;
      setInitialMoney(initialMoney);
      
      if (tournamentRanking && Object.keys(tournamentRanking).length === 0 && tournamentId) {
        try {
          const apiData = await getTournamentRanking(tournamentId);
          setLocalTournamentRanking(apiData);
        } catch (error) {
          console.error('Error fetching tournament ranking:', error);
        }
      } else {
        setLocalTournamentRanking(tournamentRanking);
      }
    };

    checkTournamentRanking();

  }, [tournamentRanking, tournamentDetails?.data?.id]);

  const tournamentArray = Object.entries(
    (tournamentRanking && Object.keys(tournamentRanking).length > 0) ? tournamentRanking : (localTournamentRanking || {})
  )


  return (
    <Box className="TournamentRankingBox">
      <BrowserView>
        <BoxContent>
          <Title>{tournamentDetails?.data?.attributes?.name}</Title>
          <RowInfo>
            {(tournamentStatus === 'IN_PROGRESS' || tournamentStatus === 'BEFORE_START') ? (
              <>
                {(tournamentStatus === 'BEFORE_START') ? (
                  <>
                    <TitleSecond>Starts in</TitleSecond>
                    <TournamentCountdown date={tournamentDetails?.data?.attributes?.start_at} />
                  </>
                ) :
                  <>
                    <TitleSecond>Time left</TitleSecond>
                    <TournamentCountdown date={tournamentDetails?.data?.attributes?.end_at} />
                  </>
               }
              </>
            ) :
              <>
                <TitleSecond>Time left</TitleSecond>
                <LineText>Finished</LineText>
              </>
            }
          </RowInfo>
          <RowInfo>
            <TitleSecond>Trading pair</TitleSecond>
            <LineText>{tradingPair.length === 0 ? `` : tradingPair?.length > 1 ? `Multi assets` : tradingPair[0]?.attributes?.name}</LineText>
          </RowInfo>
          <RowInfo>
            <TitleSecond>Total asset value</TitleSecond>
            <LineText>{formatCurrency('0.00' !== totalMoneyString ? totalMoneyString : tournamentDetails?.data?.attributes.amount_start)} USDT</LineText>
          </RowInfo>
        </BoxContent>
      </BrowserView>

      <Title>Tournament <br />Ranking</Title>
          <TournamentRank>
            {tournamentArray.length> 0 ?
              <>
              {tournamentArray.map(([player,balances]) => {
                  const totalUSDT = balances;
                  const percentageChange = ((totalUSDT - initialMoney) / initialMoney) * 100;
                  const isInvalidData = isNaN(totalUSDT) || isNaN(percentageChange);
                  return (
                    <TournamentList key={player}>
                      <WrappList className={player === username ? 'is_me' : ''}>
                        <TournamentBlet className={player === username ? 'is_me' : ''}>
                          <NickName>{player}</NickName>
                          {!isInvalidData && (<Percent>
                            {percentageChange > 0 ? `+${percentageChange.toFixed(2)}` : percentageChange.toFixed(2)}%
                          </Percent>)}
                        </TournamentBlet>
                        {!isInvalidData && (<Amount className={`AmountDetails ${totalUSDT > initialMoney ? 'up_state' : totalUSDT < initialMoney ? 'down_state' : ''}`}>
                          {formatCurrency(totalUSDT)} USDT
                        </Amount>)}
                      </WrappList>
                    </TournamentList>
                  );
                })}
              </>
            : <Load><LoadWrap/></Load>}
          </TournamentRank>
    </Box>
  );
};

export default TournamentRanking;
