import styled from 'styles/styled';
import bgGraph from '../../../assets/bg_graph.png';
import imgShadow from '../../../assets/boxJoinShadow.png';
import imgWin from '../../../assets/imgWin.svg';

export const Wrapper = styled.div`
  background-color: #15191E;
  border-radius: 12px;
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  background: linear-gradient(180deg, #15191E 0%, rgba(0, 0, 0, 0.78125) 15.45%, #15191E 100%);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  @media (max-width: 720px) {
    height: calc(100vh - 140px);
    height: calc(100svh - 140px);
    min-height: 475px;
    padding: 0;
  }
  &:after {
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    background-image: url(${imgWin});
    content: " ";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 210px;
    max-height: 250px;
    height: 20vh;
    @media (max-width: 720px) {
      right: -50px;
      height: 100px;
    }
  }
  &:before {
    background-repeat: no-repeat;
    background-position: center 50%;
    background-size: contain;
    background-image: url(${bgGraph});
    content: " ";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Content = styled.div`
  max-width: 550px;
  margin: -20px auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 40;
  position: relative;
  flex-direction: column;
  @media (max-width: 720px) {
    margin-top: 0;
  }
  &:after {
    width: 100%;
    height: 240px;
    content: " ";
    z-index: -1;
    position: absolute;
    top: -90px;
    right: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(${imgShadow});
    @media(max-width: 720px) {
      top: -100px;
    }
  }
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 30px;
  z-index: 100;
  width: 100%;
`;

export const TopContent = styled.div`
  max-width: 100%;
  overflow: hidden;
  margin-top: -100px;
  @media (max-width: 720px) {
    margin-top: 0;
  }
  img {
    width: 110%;
    overflow: hidden;
    position: relative;
    left: 50%;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    display: block;
    vertical-align: middle;
    &.DesktopImg {
      @media (max-width: 720px) {
        display: none;
      }
    }
    &.MobileImg {
      display: none;
      @media (max-width: 720px) {
        display: block;
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }
`;
