import React, { useState, useEffect } from 'react';
import ResultsWrapper from 'components/layout/ResultsWrapper';
import GraphBox from 'components/dashboard/GraphBox';
import TournamentRanking from 'components/Results/TournamentRanking';
import { WalletDataProvider } from 'context/WalletDataContext';
import { TradeUpdateProvider } from 'context/TradeUpdateContext';
import { useTournament } from 'context/TournamentContext';

import {
  Hide,
  LoadFullPage,
  LoadWrap,
} from './styled';

const Results = () => {
  const { tournamentDetails } = useTournament();
  const [isloadPage, setLoadPage] = useState(true);
    useEffect(() => {
        setTimeout(() => setLoadPage(false), 1000)
    }, [])
    if (isloadPage) {
        return <LoadFullPage><LoadWrap></LoadWrap></LoadFullPage>;
    };

  return (
    <TradeUpdateProvider>
      <ResultsWrapper>

          <WalletDataProvider tournamentDetails={tournamentDetails}>
            <>
              <TournamentRanking />
              <Hide>
                <GraphBox tournamentDetails={tournamentDetails} />
              </Hide>
            </>
          </WalletDataProvider>

      </ResultsWrapper>
    </TradeUpdateProvider>
  );
}
export default Results;
