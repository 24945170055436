import React, { useEffect, useState, useContext } from 'react';
import { TournamentCountdown } from '../TournamentCountdown/TournamentCountdown';
import { useSocket } from 'utils/hooks/useSocket';
import { formatCurrency } from 'utils';
import Cookies from 'js-cookie';
import { useTournament } from 'context/TournamentContext';
import LogoDetails from '../../../assets/resultsPhoto.png';

import {
  Title,
  SubTitle,
  Row,
  ContentPhoto,
  ContentResults,
  TournamentRank,
  LineText,
  TournamentList,
  NickName,
  Percent,
  Amount,
  TournamentBlet,
  WrappList,
  BeltBottom,
} from './styled';
import { getTournamentRanking } from 'api/tournamentService';


type TournamentRankingData = {
  [player: string]: any;
};

type TournamentDetails = {
  data?: {
    id?: number;
    attributes?: {
      name?: string;
      end_at?: string;
      amount_start?: number;
      market_type?: any;
    };
  };
};



const TournamentRanking = () => {
  const { tournamentRanking, tournamentDetails } = useSocket() as { tournamentRanking: TournamentRankingData, btcPrice: any, tournamentDetails: TournamentDetails };
  const username = localStorage.getItem('username') ?? Cookies.get('username');
  const { tournamentStatus } = useTournament();
  const [priceReward, setPriceReward] = useState(null);
  const [initialMoney, setInitialMoney] = useState(0);
  const [localTournamentRanking, setLocalTournamentRanking] = useState<TournamentRankingData | null>(null);

  useEffect(() => {
    const checkTournamentRanking = async () => {
      const tournamentId = tournamentDetails?.data?.id;
      const initialMoney = tournamentDetails?.data?.attributes?.amount_start || 1;
      setInitialMoney(initialMoney);
      ///////////////////////////////////////////////////////
      if (tournamentId == 82 || tournamentId == 85) {
        setPriceReward('Name')
      }

      if (tournamentId == 87) {
        setPriceReward('Name_87')
      }

      if (tournamentId == 85) {
        setPriceReward('Name_more')
      }

      if (tournamentId == 120) {
        setPriceReward('Name_500')
      }
      ///////////////////////////////////////////////////////
      if (tournamentRanking && Object.keys(tournamentRanking).length === 0 && tournamentId) {
        try {
          const apiData = await getTournamentRanking(tournamentId);
          setLocalTournamentRanking(apiData);
        } catch (error) {
          console.error('Error fetching tournament ranking:', error);
        }
      } else {
        setLocalTournamentRanking(tournamentRanking);
      }
    };

    checkTournamentRanking();

  }, [tournamentRanking, tournamentDetails?.data?.id]);

  const tournamentArray = Object.entries(
    (tournamentRanking && Object.keys(tournamentRanking).length > 0) ? tournamentRanking : (localTournamentRanking || {})
  )

  return (
    <>
      <Title>{tournamentDetails?.data?.attributes?.name}</Title>

      <Row>
        <ContentPhoto>
          <img src={LogoDetails} alt="Img Logo Details" />
        </ContentPhoto>
        <ContentResults>
          <SubTitle>Current Results</SubTitle>
          <TournamentRank>
            {tournamentArray.map(([player,balances]) =>{
                const totalUSDT = balances;
                const percentageChange = ((Number(totalUSDT) - initialMoney) / initialMoney) * 100;
                const isInvalidData = isNaN(Number(totalUSDT)) || isNaN(percentageChange);
                return (
                  <TournamentList key={player}>
                    <WrappList className={player === username ? 'is_me' : ''}>
                      <TournamentBlet className={player === username ? 'is_me' : ''}>
                        <NickName className={priceReward}>{player}</NickName>
                        {!isInvalidData && (<Percent>
                          {percentageChange > 0 ? `+${percentageChange.toFixed(2)}` : percentageChange.toFixed(2)}%
                        </Percent>)}
                      </TournamentBlet>
                      {!isInvalidData && (<Amount className={`${totalUSDT > initialMoney ? 'up_state' : totalUSDT < initialMoney ? 'down_state' : ''}`}>
                        {formatCurrency(totalUSDT)} USDT
                      </Amount>)}
                    </WrappList>
                  </TournamentList>
                );
              })}
          </TournamentRank>
        </ContentResults>
      </Row>

      <BeltBottom>
        <p>Time left:</p>

        {(tournamentStatus === 'IN_PROGRESS' || tournamentStatus === 'BEFORE_START') ? (
          <>
            {(tournamentStatus === 'BEFORE_START') ? (
              <SubTitle className="inner">Coming Soon</SubTitle>
            ) : <TournamentCountdown date={tournamentDetails?.data?.attributes?.end_at} /> }
          </>
        ) : <LineText>Finished</LineText> }

      </BeltBottom>
    </>
  );
};

export default TournamentRanking;
