import React from 'react';
import Cookies from 'js-cookie';

import {
  NickName,
  AvatarIcon,
} from './styled';


const Avatar = () => {
  const username = localStorage.getItem('username') ?? Cookies.get('username');


  return (
    <NickName>
      <AvatarIcon></AvatarIcon>
      {username}
    </NickName>
  );
};

export default Avatar;
