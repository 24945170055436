import React from 'react';

import {
  Box,
  Row,
  IconAvatar,
  Text,
} from './styled';

import { formatCurrency } from 'utils';
import { useWalletData } from 'context/WalletDataContext';
import { useSocket } from 'utils/hooks/useSocket';
import Cookies from 'js-cookie';


const UserState = (props: { tournamentDetails: any; }) => {
  const { tournamentDetails } = props;
  const username = localStorage.getItem('username') ?? Cookies.get('username');
  const userId = localStorage.getItem('userId') ?? Cookies.get('userId');
  const { totalMoneyString, walletData } = useWalletData();
  const { rank } = useSocket();
  const initialMoney = tournamentDetails?.data?.attributes?.amount_start || 1;

  let totalMoney = 0;
  if(tournamentDetails?.data?.attributes?.status ==='finished'){
    walletData.forEach((wallet)=>{
      if(wallet?.ticker?.short_name === 'USDT'){
        totalMoney = wallet.amount;
      }
    })
  }else if(tournamentDetails?.data?.attributes?.status ==='new'){
    totalMoney = initialMoney;
  } else{
    totalMoney = totalMoneyString;
  }

  let percentageChange = ((totalMoney - initialMoney) / initialMoney) * 100;

  if(totalMoney === 0){
    percentageChange = 0;
  }

  const parseTotalMoney = formatCurrency(totalMoney);
  let parsePercentageChange = (percentageChange.toFixed(2));

  return (
    <Box>
      <Row>
        {userId && <IconAvatar />}
        {userId && <div>
          <Text>Rank {rank}</Text>
          <Text className="NickName">{username}</Text>
          <Text>{parseTotalMoney && <>{parseTotalMoney} USDT</>}</Text>
          <Text className={`State ${percentageChange >= 0 ? 'up_state' : 'down_state'}`}>
            {parsePercentageChange && <>{parsePercentageChange}%</>}
          </Text>
        </div>}
      </Row>
    </Box>
  );
};

export default UserState;
