import React from 'react';

import {
  Wrap,
  Text,
} from './styled';


const WinInformationBitGet = () => {
  return (
    <Wrap>
      <Text>
        If you are among TOP 20 results, please fill-in the form: <a target="_blank" href="https://docs.google.com/forms/d/1lR6M7NLy-igQe0flcXM18X8UgmGYzQKc91p9o4QrMQ4/edit?ts=664455bc#">Click Here</a> and visit Bitget’s booth at Next Block Expo conference to collect the reward.
      </Text>
    </Wrap>
  );
};

export default WinInformationBitGet;
