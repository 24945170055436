import React from 'react';

import {
  Wrap,
  Text,
} from './styled';


const WinInformationBitoMat = () => {
  return (
    <Wrap>
      <Text>
        In order to collect the in-kind reward sponsored by bitomat.com, please, subscribe bitomat.com's newsletter: <a href="https://www.bitomat.com/newsletter">https://www.bitomat.com/newsletter</a> and the Team will contact you directly. In case of any additional questions feel free to contact the Team at <a href="mailto:mark@bitomat.com">mark@bitomat.com</a>
      </Text>
    </Wrap>
  );
};

export default WinInformationBitoMat;
