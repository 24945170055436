import styled from 'styles/styled';

export const Box = styled.div`
  background-color: #15191E;
  border-radius: 12px;
  padding: 15px 0;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
  @media (max-width: 1000px) {
    height: inherit;
    min-height: 300px;
  }
  @media (max-width: 720px) {
    padding: 0;
    height: initial;
    min-height: initial;
    display: block;
    margin-bottom: 0;
  }
`;

export const Wrap = styled.div`
  padding: 0 20px;
  width: 100%;
  @media (max-width: 720px) {
    padding: 0;
  }
`;

export const Currency = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 2px solid #EFEFEF;
  @media (max-width: 1000px) {
    padding-right: 10px;
    margin-right: 10px;
    font-size: 16px;
    line-height: 24px;
  }
  @media (max-width: 720px) {
    font-size: 10px;
    line-height: 20px;
    border: none;
    position: absolute;
    background-color: #292B2E;
    top: 153px;
    left: 13px;
    padding: 4px 7px;
    color: #989898;
    z-index: 10;
    border-radius: 10px;
  }
  &.hideDesktop {
    display: none;
    @media (max-width: 720px) {
      display: block;
    }
  }
`;

export const Amount = styled.h4`
  font-size: 16px;
  line-height: 15px;
  color: #52D381;
  margin-right: 15px;
  min-width: 80px;
  @media (max-width: 1000px) {
    font-size: 16px;
    min-width: 75px;
  }
  @media (max-width: 720px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const Title = styled.h6`
  font-size: 10px;
  color: #696969;
  font-weight: 700;
`;

export const Text = styled.p`
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
  color: #EFEFEF;
  &.up_state {
    color: #52D381;
  }
  &.down_state {
    color: #FF4B4A;
  }
  span {
    color: #fff;
  }
`;

export const InfoBlock = styled.div`
  margin-right: 15px;
  @media (max-width: 720px) {
    display: none;
  }
`;

export const BeltInformationDesktop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  @media (max-width: 720px) {
    display: none;
  }
`;

export const GraphWrapper = styled.div`
  width: 100%;
  min-height: 437px;
  height: calc(100vh - 600px);
  border: 1px solid #515152;
  @media (max-width: 1000px) {
    min-height: inherit;
    height: calc(100vh - 435px);
  }
  @media (max-width: 720px) {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    height: calc(100vh - 457px);
    height: calc(100svh - 457px);
  }
`;

export const BeltInformationMobile = styled.div`
  border: 1px solid #848484;
  background-color: transparent;
  display: none;
  margin-bottom: 8px;
  border-radius: 10px;
  padding: 5px 10px;
  justify-content: space-between;
  @media (max-width: 720px) {
    display: flex;
  }
`;

export const Row = styled.div`

`;

export const InfoBox = styled.div`

`;

export const Info = styled.h4`
  font-size: 12px;
  font-weight: 400;
  span {
    &.up_state {
      color: #52D381;
    }
    &.down_state {
      color: #FF4B4A;
    }
  }
`;

export const TitleSmall = styled.h5`
  font-size: 10px;
  font-weight: 400;
  color: #848484;
  margin-bottom: 3px;
`;

export const FlexWrap = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

export const LoadWrap = styled.div`
  display: block;
  width: 15px;
  height: 15px;
  animation: rotation 800ms infinite linear;
  border-radius: 100%;
  box-shadow: 0.6px 0.5px 0 0.6px #52D381;
  margin-right: 5px;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
